import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Diary(props) {
  var selectedPatient = [props.selectedpatientid];

  const [refreshState, setRefreshState] = useState(0);

  const [diaryData, setDiaryData] = useState([]);

  /*
  const [diaryAnswerWeek, setDiaryAnswerWeek] = useState("");
  const [diaryAnswerExercise, setDiaryAnswerExercise] = useState("");
  const [diaryAnswerExercisePerformance, setDiaryAnswerExercisePerformance] =
    useState([]);
  const [diaryAnswerFall, setDiaryAnswerFall] = useState("");
  const [diaryAnswerNearFall, setDiaryAnswerNearFall] = useState("");
  const [diaryAnswerInjury, setDiaryAnswerInjury] = useState("");
  const [diaryAnswerStepCount, setDiaryAnswerStepCount] = useState("");
  */

  useEffect(() => {
    axios
      .get(
        /*"/getdiarydata"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/getdiarydata",
        {
          params: { selectedPatientforDetails: props.selectedpatientid },
        }
      )
      .then(function (res) {
        console.log("Diary Data: ", res.data);
        setDiaryData(res.data);
        /*
        setDiaryAnswerWeek(res.data.diaryAnswerWeek);
        setDiaryAnswerExercise(res.data.diaryAnswerExercise);
        setDiaryAnswerExercisePerformance(
          res.data.diaryAnswerExercisePerformance
        );
        setDiaryAnswerFall(res.data.diaryAnswerFall);
        setDiaryAnswerNearFall(res.data.diaryAnswerNearFall);
        setDiaryAnswerInjury(res.data.diaryAnswerInjury);
        setDiaryAnswerStepCount(res.data.diaryAnswerStepCount);
        */
      });
  }, [props.selectedpatientid, refreshState]);
  return (
    <div>
      <table>
        <tr>
          <th>Week</th>
          <th>Exercise Record</th>
          <th>Exercise Performance</th>
          <th colSpan="3">Fall Record</th>
          <th>Health Record</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>Did you have a fall?</th>
          <th>Did you have a near fall event?</th>
          <th>Did you have an injury?</th>
          <th>Step Count</th>
        </tr>
        {diaryData.map((item) => (
          <tr>
            <td>{item.diaryAnswerWeek}</td>
            <td>{item.diaryAnswerExercise}</td>
            <td>
              <ul>
                <li>{item.diaryAnswerExercisePerformance[0]}</li>
                <li>{item.diaryAnswerExercisePerformance[1]}</li>
                <li>{item.diaryAnswerExercisePerformance[2]}</li>
              </ul>
            </td>
            <td>{item.diaryAnswerFall}</td>
            <td>{item.diaryAnswerNearFall}</td>
            <td>{item.diaryAnswerInjury}</td>
            <td>{item.diaryAnswerStepCount}</td>
          </tr>
        ))}
      </table>
    </div>
  );
}
export default Diary;
