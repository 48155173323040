import { useState } from "react";
import RecruitmentDetails from "../components/recruitmentdetails";
import Recommendations from "../components/recommendations";
import Exercises from "../components/exercises";
import Diary from "../components/diary";
import Score from "../components/scoring";

function PatientDashboard(props) {
  const [btn1Flag, setbtn1Flag] = useState("true");
  const [btn2Flag, setbtn2Flag] = useState("false");
  const [btn3Flag, setbtn3Flag] = useState("false");
  const [btn4Flag, setbtn4Flag] = useState("false");
  const [btn5Flag, setbtn5Flag] = useState("false");

  function tabButton1() {
    setbtn1Flag("true");
    setbtn2Flag("false");
    setbtn3Flag("false");
    setbtn4Flag("false");
    setbtn5Flag("false");
  }
  function tabButton2() {
    setbtn1Flag("false");
    setbtn2Flag("true");
    setbtn3Flag("false");
    setbtn4Flag("false");
    setbtn5Flag("false");
  }
  function tabButton3() {
    setbtn1Flag("false");
    setbtn2Flag("false");
    setbtn3Flag("true");
    setbtn4Flag("false");
    setbtn5Flag("false");
  }
  function tabButton4() {
    setbtn1Flag("false");
    setbtn2Flag("false");
    setbtn3Flag("false");
    setbtn4Flag("true");
    setbtn5Flag("false");
  }
  function tabButton5() {
    setbtn1Flag("false");
    setbtn2Flag("false");
    setbtn3Flag("false");
    setbtn4Flag("false");
    setbtn5Flag("true");
  }

  return (
    <div className="PatientDashboardContainer">
      <div className="selectedPatientContainer">
        <h3>Selected Patient - {props.selectedpatientid}</h3>
      </div>
      <div className="tabs-container">
        <button onClick={tabButton1} id="first-tab">
          Recruitment Details
        </button>
        <button onClick={tabButton2}>Recommendations</button>
        <button onClick={tabButton3}>Exercises</button>
        <button onClick={tabButton4}>Diary</button>
        <button onClick={tabButton5}>Score</button>
      </div>

      <div className="tabs-content-container">
        {btn1Flag === "true" ? (
          <RecruitmentDetails selectedpatientid={props.selectedpatientid} />
        ) : btn2Flag === "true" ? (
          <Recommendations selectedpatientid={props.selectedpatientid} />
        ) : btn3Flag === "true" ? (
          <Exercises selectedpatientid={props.selectedpatientid} />
        ) : btn4Flag === "true" ? (
          <Diary selectedpatientid={props.selectedpatientid} />
        ) : (
          <Score selectedpatientid={props.selectedpatientid} />
        )}
      </div>
    </div>
  );
}
export default PatientDashboard;
