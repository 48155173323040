import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

function Exercises(props) {
  var selectedPatient = [props.selectedpatientid];

  const [refreshState, setRefreshState] = useState(0);

  const [exerciseSet, setExerciseSet] = useState(1);
  const [allExerciseSet, setAllExerciseSet] = useState([]);
  const [selectedSet, setSelectedSet] = useState();

  const [allExercises, setAllExercises] = useState([]);
  const [assignedExers, setAssignedExers] = useState([]);
  const [assignedExercises, setAssignedExercises] = useState([]);
  const [assignedExercisesDetails, setAssignedExercisesDetails] = useState([]);
  const [toRemoveExerciseIds, setToRemoveExerciseIds] = useState([]);

  const [exerStartDate, setExerStartDate] = useState("");
  const [exerEndDate, setExerEndDate] = useState("");

  const [highestSet, setHighestSet] = useState();

  const [flag, setFlag] = useState(false);

  var len = allExerciseSet.length;

  //get all the available exercises
  useEffect(() => {
    console.log("current se exert..... ", selectedSet);
    axios
      .get(
        /*"/GETexercise"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/GETexercise",
        {
          params: {
            selectedPatientID: props.selectedpatientid,
            selectedSet: selectedSet,
          },
        }
      )
      .then(function (res) {
        console.log("LIST OF EXERCISES>>>>>", res.data[5]);
        setAllExerciseSet(res.data[0]);
        setExerStartDate(String(res.data[1]).slice(0, 10));
        setExerEndDate(String(res.data[2]).slice(0, 10));
        setAllExercises(res.data[3]);
        //setAssignedExercises(res.data[5][0].assExercises);
        //setAssignedExercisesDetails(res.data[2]);
        setExerciseSet(res.data[0].length);
        console.log("namesssssssss ", String(res.data[1]).slice(0, 10));

        if (flag === false) {
          if (res.data[0].length === 0) {
            console.log("settttt.. ");
            setSelectedSet(0);
          } else {
            setSelectedSet(Math.max(...res.data[0]));
            console.log("max max max.... ", Math.max(...res.data[0]));
          }
        }
        setAssignedExercises(res.data[5][0].assExercises);
      })
      .then(() => {
        setFlag(false);
      });
  }, [props.selectedpatientid, selectedSet, refreshState]);

  console.log("highest set/////////////////////////////////// ", highestSet);

  //select exercise set
  function selectExerSet(event) {
    console.log("selected set>>>>> ", event.target.dataset.value);
    setFlag(true);
    setSelectedSet(event.target.dataset.value);
  }

  function listenreps(event) {
    console.log("reps are... ", event.target.value);
    var exer = event.target.name;
    var reps = event.target.value;
    addRepsToKeyValuePairs(exer, reps);
  }

  function listenduration(event) {
    console.log("duration is... ", event.target.value);
    var exer = event.target.name;
    var duration = event.target.value;
    addDurationToKeyValuePairs(exer, duration);
  }

  function listenperformwith(event) {
    console.log("perform with are... ", event.target.value);
    var exer = event.target.name;
    var performwith = event.target.value;
    addPerformToKeyValuePairs(exer, performwith);
  }

  function listenexercisetype(event) {
    console.log("exercise type is... ", event.target.value);
    var exer = event.target.name;
    var exercisetype = event.target.value;
    addExerciseTypeToKeyValuePairs(exer, exercisetype);
  }

  function addRepsToKeyValuePairs(exer, reps) {
    const existsexerid = assignedExers.some(
      (obj) => Object.values(obj)[0] === exer
    );
    /*
    const existsexerrepkey = assignedExers.some(
      (obj) => Object.keys(obj)[0] === "Repetition"
    );
    */

    if (existsexerid === true) {
      const index = assignedExers.findIndex((item) => item.ExersiceID === exer);
      setAssignedExers((prevexers) => {
        const newArray = [...prevexers];
        newArray[index] = {
          ...newArray[index],
          ["Repetition"]: reps,
        };
        return newArray;
      });
    } else {
      setAssignedExers([
        ...assignedExers,
        { ExersiceID: exer, Repetition: reps },
      ]);
    }
  }

  function addDurationToKeyValuePairs(exer, dur) {
    const existsexerid = assignedExers.some(
      (obj) => Object.values(obj)[0] === exer
    );

    if (existsexerid === true) {
      const index = assignedExers.findIndex((item) => item.ExersiceID === exer);
      setAssignedExers((prevexers) => {
        const newArray = [...prevexers];
        newArray[index] = {
          ...newArray[index],
          ["Duration"]: dur,
        };
        return newArray;
      });
    } else {
      setAssignedExers([...assignedExers, { ExersiceID: exer, Duration: dur }]);
    }
  }

  function addPerformToKeyValuePairs(exer, performwith) {
    const existsexerid = assignedExers.some(
      (obj) => Object.values(obj)[0] === exer
    );
    /*
    const existsexerrepkey = assignedExers.some(
      (obj) => Object.keys(obj)[0] === "Repetition"
    );
    */

    if (existsexerid === true) {
      const index = assignedExers.findIndex((item) => item.ExersiceID === exer);
      setAssignedExers((prevexers) => {
        const newArray = [...prevexers];
        newArray[index] = {
          ...newArray[index],
          ["PerformWith"]: performwith,
        };
        return newArray;
      });
    } else {
      setAssignedExers([
        ...assignedExers,
        { ExersiceID: exer, PerformWith: performwith },
      ]);
    }
  }

  function addExerciseTypeToKeyValuePairs(exer, exertype) {
    const existsexerid = assignedExers.some(
      (obj) => Object.values(obj)[0] === exer
    );
    /*
    const existsexerrepkey = assignedExers.some(
      (obj) => Object.keys(obj)[0] === "Repetition"
    );
    */

    if (existsexerid === true) {
      const index = assignedExers.findIndex((item) => item.ExersiceID === exer);
      setAssignedExers((prevexers) => {
        const newArray = [...prevexers];
        newArray[index] = {
          ...newArray[index],
          ["ExerciseType"]: exertype,
        };
        return newArray;
      });
    } else {
      setAssignedExers([
        ...assignedExers,
        { ExersiceID: exer, ExerciseType: exertype },
      ]);
    }
  }

  function sendExers(event) {
    event.preventDefault();
    const config = {
      method: "POST",
      url: /*"/POSTexercise"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/POSTexercise",
      data: {
        selectedPatient,
        selectedSet,
        assignedExers,
        toRemoveExerciseIds,
      },
    };
    axios(config).then((result) => {
      console.log("sent data is:..", assignedExers);
      if (result.data === "ok") {
        document.getElementsByClassName("addResultexerGood")[0].style.display =
          "block";
        setTimeout(function hideaddResultGood() {
          document.getElementsByClassName(
            "addResultexerGood"
          )[0].style.display = "none";
        }, 2000);
      } else {
        document.getElementsByClassName("addResultexerBad")[0].style.display =
          "block";
        setTimeout(function hideaddResultBad() {
          document.getElementsByClassName("addResultexerBad")[0].style.display =
            "none";
        }, 2000);
      }
      setRefreshState(refreshState + 1);
    });
  }

  //merging
  /*
  let start = 0;
  let merge = [];

  while (start < assignedExercises.length) {
    if (assignedExercises[start].id === assignedExercisesDetails[start].id) {
      //pushing the merged objects into array
      merge.push({
        ...assignedExercises[start],
        ...assignedExercisesDetails[start],
      });
    }
    //incrementing start value
    start = start + 1;
  }
  console.log("mergerd.... ", merge);
  */

  function listenToRemoveExerciseIds(event) {
    console.log("val ", event.target.value);
    if (event.target.checked) {
      setToRemoveExerciseIds((toRemovePrev) => {
        return [...toRemovePrev, event.target.value];
      });
    } else {
      const indexToRemove = toRemoveExerciseIds.indexOf(event.target.value);
      toRemoveExerciseIds.splice(indexToRemove, 1);
      console.log("final to remove values ", toRemoveExerciseIds);
    }
  }
  console.log("final to remove values ", toRemoveExerciseIds);

  function removeExers(event) {
    event.preventDefault();
    const config = {
      method: "POST",
      url: /*"/removeexercise"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/removeexercise",
      data: {
        selectedPatient,
        selectedSet,
        toRemoveExerciseIds,
      },
    };
    axios(config).then((result) => {
      console.log("sent data is:..", assignedExers);
      setRefreshState(refreshState + 1);
    });
  }

  //enable or disable the reps and performwith on check/ uncheck
  function changeDisabled(event) {
    var reps =
      event.target.parentNode.nextElementSibling.querySelector("input").dataset
        .mykey;
    var duration =
      event.target.parentNode.nextElementSibling.nextElementSibling.querySelector(
        "input"
      ).dataset.mykey;
    var performwith =
      event.target.parentNode.nextElementSibling.nextElementSibling.nextElementSibling.querySelector(
        "select"
      ).dataset.mykey;
    var exercisetype =
      event.target.parentNode.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.querySelector(
        "select"
      ).dataset.mykey;
    console.log("perform: ", performwith);
    console.log(
      "duration: ",
      event.target.parentNode.nextElementSibling.nextElementSibling
    );
    if (
      document.getElementById(reps).disabled === true &&
      document.getElementById(duration + "duration").disabled === true &&
      document.getElementById(performwith + "pw").disabled === true &&
      document.getElementById(exercisetype + "et").disabled === true
    ) {
      //setrepsDisabled(false);
      document.getElementById(reps).disabled = false;
      document.getElementById(duration + "duration").disabled = false;
      document.getElementById(performwith + "pw").disabled = false;
      document.getElementById(exercisetype + "et").disabled = false;
    } else {
      //setrepsDisabled(true);
      document.getElementById(reps).disabled = true;
      document.getElementById(duration + "duration").disabled = true;
      document.getElementById(performwith + "pw").disabled = true;
      document.getElementById(exercisetype + "et").disabled = true;
    }
  }

  //Collapsable menu options
  function toggle(event) {
    var content = event.target.parentNode.nextElementSibling;
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  function openConfirmModalCreateSet(event) {
    console.log(
      "confirm model open ,.,.,.,,.,.",
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[3]
    );
    var confirm =
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[3];

    if (confirm.style.display === "flex") {
      confirm.style.display = "none";
    } else {
      confirm.style.display = "flex";
    }
  }

  function openConfirmModal(event) {
    console.log(
      "confirm model open ,.,.,.,,.,.",
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[2]
    );
    var confirm =
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[2];

    if (confirm.style.display === "flex") {
      confirm.style.display = "none";
    } else {
      confirm.style.display = "flex";
    }
  }

  function closeConfirmModal(event) {
    console.log(event.target.parentNode.parentNode.parentNode);
    var confirm = event.target.parentNode.parentNode.parentNode;
    confirm.style.display = "none";
  }

  function editexerciseset() {}

  function listenExerStartDate(event) {
    setExerStartDate(event.target.value);
  }

  function listenExerEndDate(event) {
    setExerEndDate(event.target.value);
  }

  function deleteExerSet(event) {
    console.log("to delete...", event.target.value);
    var exerSetToDelete = event.target.value;

    const config = {
      method: "POST",
      url: "/deleteExerSet",
      data: {
        selectedPatient,
        exerSetToDelete,
      },
    };

    axios(config).then((result) => {
      console.log("to be deleted..", exerSetToDelete);

      //setSelectedSet();
      //window.location.reload(true);
    });
  }

  function addexerciseset(event) {
    event.preventDefault();
    setExerciseSet(len + 1);
    console.log("current set >>>>>>>>> ", exerciseSet);
    const config = {
      method: "POST",
      url: /*"/postexerciseset"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/postexerciseset",
      data: {
        selectedPatient,
        exerciseSet,
        exerStartDate,
        exerEndDate,
      },
    };
    axios(config)
      .then((result) => {
        console.log("sent data is:..", result.data);
        setAllExerciseSet([...allExerciseSet, exerciseSet]);
      })
      .then(() => {
        setHighestSet(Math.max(...allExerciseSet));
        console.log(
          "><><><>><<",
          event.target.parentNode.parentNode.parentNode
        );
        var confirm = event.target.parentNode.parentNode.parentNode;
        confirm.style.display = "none";
      });
  }

  return (
    <div className="patient-exercises-parent-container">
      <div className="recommendations-set-wrapper">
        <div className="recommendations-set-controls-and-title">
          <div className="dropdown-container">
            <div className="dropdown">
              <div className="toggleview">
                <span>
                  <p className="head">
                    Recommendation Set_{Number(selectedSet) + 1}
                  </p>
                </span>
                <span className="arrow down"></span>
                <div className="dropdown-click-surface" onClick={toggle}></div>
              </div>

              <div className="content" id="content-sets">
                {allExerciseSet.map((item) => (
                  <div className="options">
                    <p data-value={item} onClick={selectExerSet}>
                      Recommendation Set_{parseInt(item) + 1}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="recommendations-set-controls">
            <div className="buttons-container">
              <button
                className="button_blue"
                id="add"
                onClick={openConfirmModalCreateSet}
              >
                Create Set
              </button>
              <button
                className="button_blue"
                id="add"
                onClick={editexerciseset}
              >
                Edit Set Info
              </button>
              <button
                className="button_red"
                id="add"
                onClick={openConfirmModal}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className="recommendations-inner-wrapper">
          <div className="dates-container">
            <div className="input-container">
              <label htmlFor="startdate">Start Date</label>
              <input
                className="input_style_1"
                onChange={listenExerStartDate}
                type="date"
                name="startdate"
                placeholder="date"
                value={exerStartDate}
              />
            </div>
            <div className="input-container">
              <label htmlFor="enddate">End Date</label>
              <input
                className="input_style_1"
                type="date"
                name="enddate"
                placeholder="date"
                onChange={listenExerEndDate}
                value={exerEndDate}
              />
            </div>
          </div>
          <div className="conditions-container">
            <div className="input-container">
              <textarea
                className="input_style_1"
                placeholder="Description"
                rows="4"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="exercises-main-wrapper">
        <div className="exercise-wrapper">
          <span>Available Exercises</span>
          {allExercises.map((item, index) => (
            <div key={item._id} className="exercise-details-container">
              <label htmlFor={item._id}>
                <input
                  className="checkboxes"
                  type="checkbox"
                  id={item.ExerciseName}
                  name={item._id}
                  value={item._id}
                  onClick={changeDisabled}
                ></input>
                <span>{item._id + " - "}</span>
                <span>{item.ExerciseName}</span>
              </label>
              <div className="reps">
                <label htmlFor={item._id}>Reps</label>
                <input
                  id={index}
                  data-mykey={index}
                  name={item._id}
                  onChange={listenreps}
                  disabled
                />
              </div>
              <div className="duration">
                <label htmlFor={item._id}>Duration</label>
                <input
                  id={index + "duration"}
                  data-mykey={index}
                  name={item._id}
                  onChange={listenduration}
                  disabled
                />
              </div>
              <div className="performwith">
                <select
                  id={index + "pw"}
                  data-mykey={index}
                  name={item._id}
                  onChange={listenperformwith}
                  disabled
                >
                  <option disabled value="Perform with" selected>
                    Perform With
                  </option>
                  <option value="Assistance">Assistance</option>
                  <option value="Independently">Independently</option>
                  <option value="UnderSupervision">Under Supervision</option>
                  <option value="AssistanceSupervision">
                    Assistance & Supervision
                  </option>
                </select>
              </div>
              <div className="exercisetype">
                <select
                  id={index + "et"}
                  data-mykey={index}
                  name={item._id}
                  onChange={listenexercisetype}
                  disabled
                >
                  <option disabled value="Task type" selected>
                    Task Type
                  </option>
                  <option value="MentalTask">Mental Task</option>
                  <option value="PhysicalTask">Physical Task</option>
                </select>
              </div>
            </div>
          ))}
          <div className="buttons-container">
            <button className="button_blue" id="remove" onClick={sendExers}>
              Add
            </button>
          </div>
          <div className="addResultexerGood">Added</div>
          <div className="addResultexerBad">Something went wrong</div>
        </div>

        <div className="exercise-wrapper">
          <span>Assigned Exercises</span>
          {assignedExercises.map((assigneditem, index) => (
            <div className="exercise-details-container">
              <input
                className="checkboxes"
                type="checkbox"
                name={assigneditem._id}
                value={assigneditem.ExersiceID}
                onClick={listenToRemoveExerciseIds}
              ></input>
              <label htmlFor={assigneditem._id}>
                {assigneditem.ExersiceID}
              </label>
              <div className="reps">
                <label htmlFor={assigneditem._id}>Reps</label>
                <input
                  id={index}
                  data-mykey={index}
                  name={assigneditem._id}
                  value={assigneditem.Repetition}
                  disabled
                />
              </div>
              <div className="performwith">
                <label htmlFor={assigneditem._id}>Perform With: </label>
                <input
                  id={index}
                  data-mykey={index}
                  name={assigneditem._id}
                  value={assigneditem.PerformWith}
                  disabled
                />
              </div>
            </div>
          ))}
          <div className="buttons-container">
            <button className="button_red" id="remove" onClick={removeExers}>
              Remove
            </button>
          </div>
        </div>
      </div>

      {/*

      <button className="save-button" type="button" onClick={sendExers}>
        Send
      </button>
       */}
      <div className="confirm-modal">
        <div className="confirm-box">
          <p>Confirm Deletion of Set {Number(selectedSet) + 1}</p>
          <div className="confirm-cancel">
            <button
              className="button_red"
              id="confirm"
              onClick={deleteExerSet}
              value={selectedSet}
            >
              Confirm
            </button>
            <button
              className="button_blue"
              id="cancel"
              onClick={closeConfirmModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="confirm-modal" id="confirm-modal-create-set">
        <div className="confirm-box">
          <p>Confirm starting of a new Set</p>
          <div className="confirm-cancel">
            <button
              className="button_red"
              id="confirm"
              onClick={addexerciseset}
            >
              Confirm
            </button>
            <button
              className="button_blue"
              id="cancel"
              onClick={closeConfirmModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Exercises;
