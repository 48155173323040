import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

function Score(props) {
  var selectedPatient = [props.selectedpatientid];

  function tabButton1(event) {
    document.getElementById("quizScore").style.display = "flex";
    document.getElementById("outcomeScore").style.display = "none";
    document.getElementById("goalsScore").style.display = "none";
    document.getElementById("exerScore").style.display = "none";
  }
  function tabButton2() {
    document.getElementById("exerScore").style.display = "flex";
    document.getElementById("quizScore").style.display = "none";
    document.getElementById("goalsScore").style.display = "none";
    document.getElementById("outcomeScore").style.display = "none";
  }
  function tabButton3() {
    document.getElementById("goalsScore").style.display = "flex";
    document.getElementById("quizScore").style.display = "none";
    document.getElementById("outcomeScore").style.display = "none";
    document.getElementById("exerScore").style.display = "none";
  }
  function tabButton4() {
    document.getElementById("outcomeScore").style.display = "flex";
    document.getElementById("quizScore").style.display = "none";
    document.getElementById("goalsScore").style.display = "none";
    document.getElementById("exerScore").style.display = "none";
  }

  const [patStartDate, setPatStartDate] = useState("");
  const [refreshState, setRefreshState] = useState(0);

  const [QuizScoreList, setQuizScoreList] = useState([]);
  const [totalQuizScore, setTotalQuizScore] = useState("NA");

  const [ExerScoreList, setExerScoreList] = useState([]);
  const [totalExerScore, setTotalExerScore] = useState("NA");

  const [thScoreWeek, setThScoreWeek] = useState("");

  const [GoalsScoreList, setGoalsScoreList] = useState([]);

  const [thGoal1Score, setThGoal1Score] = useState("");
  const [thGoal2Score, setThGoal2Score] = useState("");
  const [thGoal3Score, setThGoal3Score] = useState("");
  const [totalGoalsScore, setTotalGoalsScore] = useState("NA");

  const [totalOutcomePreScore, setTotalOutcomePreScore] = useState("NA");

  const [totalGrandTotal, setTotalGrandTotal] = useState("NA");

  const [test1Score, setTest1Score] = useState("");
  const [test2Score, setTest2Score] = useState("");
  const [test3Score, setTest3Score] = useState("");
  const [test4Score, setTest4Score] = useState("");
  const [test5Score, setTest5Score] = useState("");
  const [test6Score, setTest6Score] = useState("");
  const [test7Score, setTest7Score] = useState("");
  const [test8Score, setTest8Score] = useState("");

  const [test1ScorePost, setTest1ScorePost] = useState("");
  const [test2ScorePost, setTest2ScorePost] = useState("");
  const [test3ScorePost, setTest3ScorePost] = useState("");
  const [test4ScorePost, setTest4ScorePost] = useState("");
  const [test5ScorePost, setTest5ScorePost] = useState("");
  const [test6ScorePost, setTest6ScorePost] = useState("");
  const [test7ScorePost, setTest7ScorePost] = useState("");
  const [test8ScorePost, setTest8ScorePost] = useState("");

  useEffect(() => {
    axios
      .get(
        /*"/GETscoreWeb"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/GETscoreWeb",
        {
          params: { selectedPatientforDetails: props.selectedpatientid },
        }
      )
      .then(function (res) {
        console.log("score list: ", res.data[6]);
        setPatStartDate(res.data[0]);
        setQuizScoreList(res.data[1]);
        setTotalQuizScore(res.data[2]);
        setExerScoreList(res.data[3]);
        setTotalExerScore(res.data[4]);
        setGoalsScoreList(res.data[5]);
        var temp = [];
        res.data[5].map((item) => temp.push(item.GoalsPointsWeek));
        console.log("score week::: ", Math.max(...temp));
        setThScoreWeek(Math.max(...temp));
        setTotalGoalsScore(res.data[6]);

        //setTotalGoalsScore(res.data[7]);
        setTotalOutcomePreScore(res.data[8]);
        setTotalGrandTotal(res.data[9]);
      });
  }, [props.selectedpatientid, refreshState]);

  function listenThScoreWeek(event) {
    setThScoreWeek(event.target.value);
  }

  function getThGoal1score(event) {
    setThGoal1Score(event.target.value);
  }
  function getThGoal2score(event) {
    setThGoal2Score(event.target.value);
  }
  function getThGoal3score(event) {
    setThGoal3Score(event.target.value);
  }

  function sendThGoalsScore(event) {
    event.preventDefault();
    const config = {
      method: "POST",
      url: /*"/POSTgoalsThScore"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/POSTgoalsThScore",
      data: {
        selectedPatient,
        thScoreWeek,
        thGoal1Score,
        thGoal2Score,
        thGoal3Score,
      },
    };
    axios(config).then((result) => {
      setThGoal1Score("");
      setThGoal2Score("");
      setThGoal3Score("");
      console.log("sent data is:..", result.data);
      if (result.data === "ok") {
        document.getElementsByClassName("addResultGood")[0].style.display =
          "block";
        setTimeout(function hideaddResultGood() {
          document.getElementsByClassName("addResultGood")[0].style.display =
            "none";
        }, 2000);
      } else {
        document.getElementsByClassName("addResultBad")[0].style.display =
          "block";
        setTimeout(function hideaddResultBad() {
          document.getElementsByClassName("addResultBad")[0].style.display =
            "none";
        }, 2000);
      }
      setRefreshState(refreshState + 1);
    });
  }

  function getTest1Value(event) {
    setTest1Score(event.target.value);
  }
  function getTest2Value(event) {
    setTest2Score(event.target.value);
  }
  function getTest3Value(event) {
    setTest3Score(event.target.value);
  }
  function getTest4Value(event) {
    setTest4Score(event.target.value);
  }
  function getTest5Value(event) {
    setTest5Score(event.target.value);
  }
  function getTest6Value(event) {
    setTest6Score(event.target.value);
  }
  function getTest7Value(event) {
    setTest7Score(event.target.value);
  }
  function getTest8Value(event) {
    setTest8Score(event.target.value);
  }

  function getTest1ValuePost(event) {
    setTest1ScorePost(event.target.value);
  }
  function getTest2ValuePost(event) {
    setTest2ScorePost(event.target.value);
  }
  function getTest3ValuePost(event) {
    setTest3ScorePost(event.target.value);
  }
  function getTest4ValuePost(event) {
    setTest4ScorePost(event.target.value);
  }
  function getTest5ValuePost(event) {
    setTest5ScorePost(event.target.value);
  }
  function getTest6ValuePost(event) {
    setTest6ScorePost(event.target.value);
  }
  function getTest7ValuePost(event) {
    setTest7ScorePost(event.target.value);
  }
  function getTest8ValuePost(event) {
    setTest8ScorePost(event.target.value);
  }

  function sendPostScore(event) {
    event.preventDefault();
    const config = {
      method: "POST",
      url: /*"/POSToutcomePostScore"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/POSToutcomePostScore",
      data: {
        selectedPatient,
        test1ScorePost,
        test2ScorePost,
        test3ScorePost,
        test4ScorePost,
        test5ScorePost,
        test6ScorePost,
        test7ScorePost,
        test8ScorePost,
      },
    };
    axios(config).then((result) => {
      setTest1ScorePost("");
      setTest2ScorePost("");
      setTest3ScorePost("");
      setTest4ScorePost("");
      setTest5ScorePost("");
      setTest6ScorePost("");
      setTest7ScorePost("");
      setTest8ScorePost("");
      console.log("sent data is:..", result.data);
      if (result.data === "ok") {
        document.getElementsByClassName("addResultGood")[0].style.display =
          "block";
        setTimeout(function hideaddResultGood() {
          document.getElementsByClassName("addResultGood")[0].style.display =
            "none";
        }, 2000);
      } else {
        document.getElementsByClassName("addResultBad")[0].style.display =
          "block";
        setTimeout(function hideaddResultBad() {
          document.getElementsByClassName("addResultBad")[0].style.display =
            "none";
        }, 2000);
      }
    });
  }

  function sendPreScore(event) {
    event.preventDefault();
    const config = {
      method: "POST",
      url: /*"/POSToutcomePreScore"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/POSToutcomePreScore",
      data: {
        selectedPatient,
        test1Score,
        test2Score,
        test3Score,
        test4Score,
        test5Score,
        test6Score,
        test7Score,
        test8Score,
      },
    };
    axios(config).then((result) => {
      setTest1Score("");
      setTest2Score("");
      setTest3Score("");
      setTest4Score("");
      setTest5Score("");
      setTest6Score("");
      setTest7Score("");
      setTest8Score("");
      console.log("sent data is:..", result.data);
      if (result.data === "ok") {
        document.getElementsByClassName("addResultGood1")[0].style.display =
          "block";
        setTimeout(function hideaddResultGood() {
          document.getElementsByClassName("addResultGood1")[0].style.display =
            "none";
        }, 2000);
      } else {
        document.getElementsByClassName("addResultBad1")[0].style.display =
          "block";
        setTimeout(function hideaddResultBad() {
          document.getElementsByClassName("addResultBad1")[0].style.display =
            "none";
        }, 2000);
      }
      setRefreshState(refreshState + 1);
    });
  }

  function showHideScoreDetails(event) {
    var goalsScoreDetails =
      document.getElementsByClassName("goalScoreDetails")[0];
    console.log("dahdksahdkjhakjdhkajhda: ", goalsScoreDetails);
    if ((goalsScoreDetails.style.display = "none")) {
      goalsScoreDetails.style.display = "block";
    }
    if ((goalsScoreDetails.style.display = "block")) {
      goalsScoreDetails.style.display = "none";
    }
  }

  return (
    <div className="score-wrapper">
      <div className="totalScore">
        <p>TOTAL</p>
        <p>{totalGrandTotal}</p>
      </div>

      <div className="scoreTabs">
        <button onClick={tabButton1} id="first-tab">
          Quiz
        </button>
        <button onClick={tabButton2}>Exercise Adherence</button>
        <button onClick={tabButton3}>Goals</button>
        <button onClick={tabButton4}>Outcome Measure</button>
      </div>
      <div className="scoreBox" id="quizScore">
        {QuizScoreList.map((item) => (
          <div className="scoreWeekPoints">
            <div className="weekHeading">
              <h4>Week: {item.quizWeek}</h4>
            </div>

            <p>Score: {item.quizPoints}</p>
          </div>
        ))}
        <br></br>
        <h4>Total Quiz Score: {totalQuizScore}</h4>
      </div>
      <div className="scoreBox" id="exerScore">
        {ExerScoreList.map((item) => (
          <div className="scoreWeekPoints">
            <div className="weekHeading">
              <h4>Week: {item.exerPointsWeek}</h4>
            </div>

            <p>Exercise Adherence Score: {item.exerPoints}</p>
            <br></br>
          </div>
        ))}
        <h4>Total Exercise Adherence Score: {totalExerScore}</h4>
      </div>
      <div className="scoreBox" id="goalsScore">
        {GoalsScoreList.map((item) => (
          <div className="scoreWeekPoints">
            <div className="weekHeading" onClick={showHideScoreDetails}>
              <h4>Week: {item.GoalsPointsWeek}</h4>
            </div>

            <div className="goalScoreDetails">
              <p className="goalHeading">Goal 1</p>
              <p>Participant Score: {item.goal1PatPoints}</p>
              <p>Therapist Score: {item.goal1ThPoints}</p>
              <br></br>
              <p className="goalHeading">Goal 2</p>
              <p>Participant Score: {item.goal2PatPoints}</p>
              <p>Therapist Score: {item.goal2ThPoints}</p>
              <br></br>
              <p className="goalHeading">Goal 3</p>
              <p>GoalParticipant Score: {item.goal3PatPoints}</p>
              <p>Therapist Score: {item.goal3ThPoints}</p>
            </div>
            <br></br>
          </div>
        ))}
        <h4>Total Goals Score: {totalGoalsScore}</h4>
        <br></br>
        <h4>Therapist scoring:</h4>
        <p>Select a week</p>
        <select
          className="input_style_1"
          id="input_week_style_1"
          placeholder="Select a week"
          onChange={listenThScoreWeek}
        >
          {GoalsScoreList.map((item) => (
            <option value={item.GoalsPointsWeek} selected>
              Week {item.GoalsPointsWeek}
            </option>
          ))}
        </select>
        <p>Goal1</p>
        <div className="therapistScoreOptions">
          <label htmlFor="g1notachieved">
            Not Achieved
            <input
              id="g1notachieved"
              type="radio"
              name="g1thscoring"
              value="0"
              onClick={getThGoal1score}
            ></input>
          </label>
          <label htmlFor="g1partiallyachieved">
            Partially Achieved
            <input
              id="g1partiallyachieved"
              type="radio"
              name="g1thscoring"
              value="5"
              onClick={getThGoal1score}
            ></input>
          </label>
          <label htmlFor="g1fullyachieved">
            Fully Achieved
            <input
              id="g1fullyachieved"
              type="radio"
              name="g1thscoring"
              value="10"
              onClick={getThGoal1score}
            ></input>
          </label>
        </div>
        <br></br>
        <p>Goal2</p>
        <div className="therapistScoreOptions">
          <label htmlFor="g2notachieved">
            Not Achieved
            <input
              id="g2notachieved"
              type="radio"
              name="g2thscoring"
              value="0"
              onClick={getThGoal2score}
            ></input>
          </label>
          <label htmlFor="g2partiallyachieved">
            Partially Achieved
            <input
              id="g2partiallyachieved"
              type="radio"
              name="g2thscoring"
              value="5"
              onClick={getThGoal2score}
            ></input>
          </label>
          <label htmlFor="g2fullyachieved">
            Fully Achieved
            <input
              id="g2fullyachieved"
              type="radio"
              name="g2thscoring"
              value="10"
              onClick={getThGoal2score}
            ></input>
          </label>
        </div>

        <br></br>
        <p>Goal3</p>
        <div className="therapistScoreOptions">
          <label htmlFor="g3notachieved">
            Not Achieved
            <input
              id="g3notachieved"
              type="radio"
              name="g3thscoring"
              value="0"
              onClick={getThGoal3score}
            ></input>
          </label>
          <label htmlFor="g3partiallyachieved">
            Partially Achieved
            <input
              id="g3partiallyachieved"
              type="radio"
              name="g3thscoring"
              value="5"
              onClick={getThGoal3score}
            ></input>
          </label>
          <label htmlFor="g3fullyachieved">
            Fully Achieved
            <input
              id="g3fullyachieved"
              type="radio"
              name="g3thscoring"
              value="10"
              onClick={getThGoal3score}
            ></input>
          </label>
        </div>
        <button className="button_blue" onClick={sendThGoalsScore}>
          Submit
        </button>
        <div className="addResultGood">Added</div>
        <div className="addResultBad">Something went wrong</div>
      </div>

      <div className="scoreBox" id="outcomeScore">
        <div className="prePostOutcome">
          <div className="preTest">
            <h4>Pre test</h4>
            <div className="testFields">
              <p>Timed up and Go test</p>
              <label htmlFor="pret1poor">
                Below Average/ Poor
                <input
                  id="pret1poor"
                  type="radio"
                  name="test1pre"
                  value="0"
                  onClick={getTest1Value}
                ></input>
              </label>
              <label htmlFor="pret1average">
                Average/ Fair
                <input
                  id="pret1average"
                  type="radio"
                  name="test1pre"
                  value="5"
                  onClick={getTest1Value}
                ></input>
              </label>
              <label htmlFor="pret1good">
                Above Average/ Good
                <input
                  id="pret1good"
                  type="radio"
                  name="test1pre"
                  value="10"
                  onClick={getTest1Value}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Timed up and Go test - Manual</p>
              <label htmlFor="pret2poor">
                Below Average/ Poor
                <input
                  id="pret2poor"
                  type="radio"
                  name="test2pre"
                  value="0"
                  onClick={getTest2Value}
                ></input>
              </label>
              <label htmlFor="pret2average">
                Average/ Fair
                <input
                  id="pret2average"
                  type="radio"
                  name="test2pre"
                  value="5"
                  onClick={getTest2Value}
                ></input>
              </label>
              <label htmlFor="pret2good">
                Above Average/ Good
                <input
                  id="pret2good"
                  type="radio"
                  name="test2pre"
                  value="10"
                  onClick={getTest2Value}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Timed up and Go test - cognitive</p>
              <label htmlFor="pret3poor">
                Below Average/ Poor
                <input
                  id="pret3poor"
                  type="radio"
                  name="test3pre"
                  value="0"
                  onClick={getTest3Value}
                ></input>
              </label>
              <label htmlFor="pret3average">
                Average/ Fair
                <input
                  id="pret3average"
                  type="radio"
                  name="test3pre"
                  value="5"
                  onClick={getTest3Value}
                ></input>
              </label>
              <label htmlFor="pret3good">
                Above Average/ Good
                <input
                  id="pret3good"
                  type="radio"
                  name="test3pre"
                  value="10"
                  onClick={getTest3Value}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Seconds 30 chair test</p>
              <label htmlFor="pret4poor">
                Below Average/ Poor
                <input
                  id="pret4poor"
                  type="radio"
                  name="test4pre"
                  value="0"
                  onClick={getTest4Value}
                ></input>
              </label>
              <label htmlFor="pret4average">
                Average/ Fair
                <input
                  id="pret4average"
                  type="radio"
                  name="test4pre"
                  value="5"
                  onClick={getTest4Value}
                ></input>
              </label>
              <label htmlFor="pret4good">
                Above Average/ Good
                <input
                  id="pret4good"
                  type="radio"
                  name="test4pre"
                  value="10"
                  onClick={getTest4Value}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Home related Fall Risk Assessment</p>
              <label htmlFor="pret5poor">
                Below Average/ Poor
                <input
                  id="pret5poor"
                  type="radio"
                  name="test5pre"
                  value="0"
                  onClick={getTest5Value}
                ></input>
              </label>
              <label htmlFor="pret5average">
                Average/ Fair
                <input
                  id="pret5average"
                  type="radio"
                  name="test5pre"
                  value="5"
                  onClick={getTest5Value}
                ></input>
              </label>
              <label htmlFor="pret5good">
                Above Average/ Good
                <input
                  id="pret5good"
                  type="radio"
                  name="test5pre"
                  value="10"
                  onClick={getTest5Value}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Fall efficacy Scale - International</p>
              <label htmlFor="pret6poor">
                Below Average/ Poor
                <input
                  id="pret6poor"
                  type="radio"
                  name="test6pre"
                  value="0"
                  onClick={getTest6Value}
                ></input>
              </label>
              <label htmlFor="pret6average">
                Average/ Fair
                <input
                  id="pret6average"
                  type="radio"
                  name="test6pre"
                  value="5"
                  onClick={getTest6Value}
                ></input>
              </label>
              <label htmlFor="pret6good">
                Above Average/ Good
                <input
                  id="pret6good"
                  type="radio"
                  name="test6pre"
                  value="10"
                  onClick={getTest6Value}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Fall related knowledge, attitude and practice</p>
              <label htmlFor="pret7poor">
                Below Average/ Poor
                <input
                  id="pret7poor"
                  type="radio"
                  name="test7pre"
                  value="0"
                  onClick={getTest7Value}
                ></input>
              </label>
              <label htmlFor="pret7average">
                Average/ Fair
                <input
                  id="pret7average"
                  type="radio"
                  name="test7pre"
                  value="5"
                  onClick={getTest7Value}
                ></input>
              </label>
              <label htmlFor="pret7good">
                Above Average/ Good
                <input
                  id="pret7good"
                  type="radio"
                  name="test7pre"
                  value="10"
                  onClick={getTest7Value}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>General Self efficacy scale</p>
              <label htmlFor="pret8poor">
                Below Average/ Poor
                <input
                  id="pret8poor"
                  type="radio"
                  name="test8pre"
                  value="0"
                  onClick={getTest8Value}
                ></input>
              </label>
              <label htmlFor="pret8average">
                Average/ Fair
                <input
                  id="pret8average"
                  type="radio"
                  name="test8pre"
                  value="5"
                  onClick={getTest8Value}
                ></input>
              </label>
              <label htmlFor="pret8good">
                Above Average/ Good
                <input
                  id="pret8good"
                  type="radio"
                  name="test8pre"
                  value="10"
                  onClick={getTest8Value}
                ></input>
              </label>
            </div>
            <div className="preTestScore">
              <h4>Total Pre Test Score: {totalOutcomePreScore}</h4>
            </div>
            <button className="button_blue" onClick={sendPreScore}>
              Submit
            </button>
            <div className="addResultGood1">Added</div>
            <div className="addResultBad1">Something went wrong</div>
          </div>

          <div className="postTest">
            <h4>Post test</h4>
            <div className="testFields">
              <p>Timed up and Go test</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test1post"
                  value="0"
                  onClick={getTest1ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test1post"
                  value="5"
                  onClick={getTest1ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test1post"
                  value="10"
                  onClick={getTest1ValuePost}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Timed up and Go test - Manual</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test2post"
                  value="0"
                  onClick={getTest2ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test2post"
                  value="5"
                  onClick={getTest2ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test2post"
                  value="10"
                  onClick={getTest2ValuePost}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Timed up and Go test - cognitive</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test3post"
                  value="0"
                  onClick={getTest3ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test3post"
                  value="5"
                  onClick={getTest3ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test3post"
                  value="10"
                  onClick={getTest3ValuePost}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Seconds 30 chair test</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test4post"
                  value="0"
                  onClick={getTest4ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test4post"
                  value="5"
                  onClick={getTest4ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test4post"
                  value="10"
                  onClick={getTest4ValuePost}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Home related Fall Risk Assessment</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test5post"
                  value="0"
                  onClick={getTest5ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test5post"
                  value="5"
                  onClick={getTest5ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test5post"
                  value="10"
                  onClick={getTest5ValuePost}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Fall efficacy Scale - International</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test6post"
                  value="0"
                  onClick={getTest6ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test6post"
                  value="5"
                  onClick={getTest6ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test6post"
                  value="10"
                  onClick={getTest6ValuePost}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>Fall related knowledge, attitude and practice</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test7post"
                  value="0"
                  onClick={getTest7ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test7post"
                  value="5"
                  onClick={getTest7ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test7post"
                  value="10"
                  onClick={getTest7ValuePost}
                ></input>
              </label>
            </div>
            <div className="testFields">
              <p>General Self efficacy scale</p>
              <label htmlFor="poor">
                Below Average/ Poor
                <input
                  id="poor"
                  type="radio"
                  name="test8post"
                  value="0"
                  onClick={getTest8ValuePost}
                ></input>
              </label>
              <label htmlFor="average">
                Average/ Fair
                <input
                  id="average"
                  type="radio"
                  name="test8post"
                  value="5"
                  onClick={getTest8ValuePost}
                ></input>
              </label>
              <label htmlFor="good">
                Above Average/ Good
                <input
                  id="good"
                  type="radio"
                  name="test8post"
                  value="10"
                  onClick={getTest8ValuePost}
                ></input>
              </label>
            </div>
            <button className="button_blue" onClick={sendPostScore}>
              send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Score;
