function AddPatient(props) {
  //console.log("statusssss ", props.addStatus);
  console.log(document.getElementById("addResultGoodd"));
  //document.getElementById("addResultGoodd").style.display = "block";

  /*
  if (props.addStatus === "") {
    console.log("statussssssss blank", props.addStatus);
    
      document.getElementsByClassName("addResultGood")[0].style.display = "none";
    document.getElementsByClassName("addResultBad")[0].style.display = "none";
  
  } else if (props.addStatus === "Participant Added") {
    console.log("statussssssss added", props.addStatus);
    
    document.getElementsByClassName("addResultGood")[0].style.display = "block";
    setTimeout(function hideaddResultGood() {
      document.getElementsByClassName("addResultGood")[0].style.display =
        "none";
    }, 2000);
    
  } else {
    console.log("statussssssss exists", props.addStatus);
    
    document.getElementsByClassName("addResultBad")[0].style.display = "block";
    setTimeout(function hideaddResultBad() {
      document.getElementsByClassName("addResultBad")[0].style.display = "none";
    }, 2000);
    
  }
  */

  return (
    <div className="add-patient-container">
      <div className="addPatientHeadingContainer">
        <h3>Add a new Patient</h3>
      </div>
      <form onSubmit={props.addnewpatient} method="POST">
        <div className="input-container">
          <span className="errorMsg">ID can not be blank !</span>

          <input
            id="hospitalId"
            className="input_style_1"
            onChange={props.listenpatientID}
            type="text"
            placeholder="Patient ID"
            value={props.patientid}
          />
        </div>

        <div className="input-container">
          <span className="errorMsg"> Phone Number can not be blank! </span>
          <span className="errorMsg"> Invalid Number! </span>
          <input
            id="patPhoneNumber"
            className="input_style_1"
            onChange={props.listenpatientmobilenumber}
            type="text"
            placeholder="Patient Mobile"
            value={props.patientmobilenumber}
          />
        </div>

        <div className="input-container">
          <textarea
            className="input_style_1"
            onChange={props.listenpatientdescription}
            placeholder="Description"
            value={props.patientdescription}
          />
        </div>

        <div className="input-container">
          <span className="errorMsg">Age can not be blank! </span>
          <span className="errorMsg">Only Numbers!</span>
          <input
            id="patAge"
            className="input_style_1"
            onChange={props.listenpatientage}
            placeholder="Age"
            value={props.patientage}
          />
        </div>

        <div className="input-container">
          <select
            className="input_style_1"
            onChange={props.listenpatientgender}
            placeholder="Gender"
          >
            <option disabled selected value>
              Gender
            </option>

            <option value="Female">F</option>
            <option value="Male">M</option>
          </select>
        </div>

        <div className="input-container">
          <textarea
            className="input_style_1"
            onChange={props.listenpatientGoal1}
            placeholder="Goal 1"
            value={props.patientGoal1}
          />
        </div>
        <div className="input-container">
          <textarea
            className="input_style_1"
            onChange={props.listenpatientGoal2}
            placeholder="Goal 2"
            value={props.patientGoal2}
          />
        </div>
        <div className="input-container">
          <textarea
            className="input_style_1"
            onChange={props.listenpatientGoal3}
            placeholder="Goal 3"
            value={props.patientGoal3}
          />
        </div>

        <div className="input-container">
          <label htmlFor="startdate">Start Date</label>
          <input
            className="input_style_1"
            onChange={props.listenstartdate}
            type="date"
            name="startdate"
            placeholder="date"
            value={props.startdate}
          />
        </div>

        <div className="input-container">
          <label htmlFor="endddate">End Date</label>
          <input
            className="input_style_1"
            onChange={props.listenenddate}
            type="date"
            name="enddate"
            value={props.enddate}
          />
        </div>

        <div className="input-container" id="relationship">
          <input
            className="input_style_1"
            onChange={props.listenenddate}
            type="text"
            name="enddate"
            placeholder="Relationship to the patient"
          />
        </div>

        <div id="addResultGoodd" className="addResultGoodd">
          Added
        </div>
        <div className="addResultBadd">ID Already Exists!</div>

        <div className="input-button-container">
          <button className="button_blue" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
export default AddPatient;
