import "../styles/help-style.css";
function Help() {
  return (
    <div>
      <div className="pdfContainer">
        <object
          className="pdf"
          data="https://firebasestorage.googleapis.com/v0/b/projectseniorsaathilive.appspot.com/o/boatfaq.pdf?alt=media&token=f293fe5b-8ae4-4284-bf31-fafd60ff50da&_gl=1*15r7ba2*_ga*MTI3OTA0MTkxLjE2OTE3MzUzNzU.*_ga_CW55HF8NVT*MTY5NjUwODc1OC42LjEuMTY5NjUwODg0My42MC4wLjA."
          type="application/pdf"
        ></object>
      </div>
    </div>
  );
}
export default Help;
