import { useState } from "react";
import Home from "./Home";

function Cms() {
  var tagLineText;
  const [taglineEditable, setTagLineEditable] = useState(false);

  function monitorChanges(event) {
    console.log(document.getElementById("tagline").innerHTML);
  }

  function changeTagLineEditValue() {
    setTagLineEditable(true);
    console.log("current tag line edit value: " + taglineEditable);
  }

  async function saveChanges(event) {
    console.log(document.getElementById("tagline").innerHTML);
    tagLineText = document.getElementById("tagline").innerHTML;

    //Sending the data to Nodejs Backend
    const res = await fetch("/Cms", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        tagLineText,
      }),
    });
    await res.json();
  }

  return (
    <div className="cms-main-container">
      <div className="cms-header-container">
        <p>Content Management System</p>
        <button onClick={saveChanges}>Save</button>
      </div>
      <div className="page-container">
        <Home
          taglineID={"tagline"}
          taglineEditValue={taglineEditable}
          triggerChangeTagLineEditValue={changeTagLineEditValue}
          monitorchanges={monitorChanges}
        />
      </div>
    </div>
  );
}
export default Cms;
