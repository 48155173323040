import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function RecruitmentDetails(props) {
  var selectedPatient = [props.selectedpatientid];

  const [detailsPatMobile, setDetailsPatMobile] = useState("");
  const [detailsDescription, setDetailsDescription] = useState("");
  const [detailsAge, setDetailsAge] = useState("");
  const [detailsGender, setDetailsGender] = useState("");
  const [detailsPatStartDate, setDetailsPatStartDate] = useState("");
  const [detailsPatEndDate, setDetailsPatEndDate] = useState("");
  const [detailsPatGoal1, setDetailsPatGoal1] = useState("");
  const [detailsPatGoal2, setDetailsPatGoal2] = useState("");
  const [detailsPatGoal3, setDetailsPatGoal3] = useState("");

  useEffect(() => {
    axios
      .get(
        /*"/getrecruitmentdetails"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/getrecruitmentdetails",
        {
          params: { selectedPatientforDetails: props.selectedpatientid },
        }
      )
      .then(function (res) {
        console.log("got back for details ", res.data.GoalsData.Goal1);

        setDetailsPatMobile(res.data.PatientMobile);
        setDetailsDescription(res.data.PatientDescription);
        setDetailsAge(res.data.PatientAge);
        setDetailsGender(res.data.PatientGender);
        setDetailsPatStartDate(res.data.StartDate);
        setDetailsPatEndDate(res.data.EndDate);
        setDetailsPatGoal1(res.data.GoalsData.Goal1);
        setDetailsPatGoal2(res.data.GoalsData.Goal2);
        setDetailsPatGoal3(res.data.GoalsData.Goal3);
      });
  }, [props.selectedpatientid]);

  function deletePatient(event) {
    console.log("to delete...", event.target.value);
    var patientToDelete = event.target.value;

    const config = {
      method: "POST",
      url: /*"/deletepatient"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/deletepatient",
      data: {
        patientToDelete,
      },
    };

    axios(config).then((result) => {
      console.log("to be deleted..", patientToDelete);
      window.location.reload(true);
    });

    var confirm = event.target.parentNode.parentNode.parentNode;
    confirm.style.display = "none";
  }

  const [inputPhoneDisabled, setInputPhoneDisabled] = useState();
  const [inputGoal1Disabled, setInputGoal1Disabled] = useState();
  const [inputGoal2Disabled, setInputGoal2Disabled] = useState();
  const [inputGoal3Disabled, setInputGoal3Disabled] = useState();
  const [inputStartDateDisabled, setInputStartDateDisabled] = useState();
  const [inputEndDateDisabled, setInputEndDateDisabled] = useState();
  /*
  function editPhone() {
    console.log("disabled???? ", inputEndDateDisabled);
    if (inputPhoneDisabled === true) {
      setInputPhoneDisabled(false);
      document.getElementById("inputPhone").disabled = inputPhoneDisabled;
    } else {
      setInputPhoneDisabled(true);
      document.getElementById("inputPhone").disabled = inputPhoneDisabled;
    }
  }
  */
  /*
  function editStartDate() {
    setInputStartDateDisabled(false);
    document.getElementById("inputStartDate").disabled = inputStartDateDisabled;
  }
  function editEndDate() {
    setInputEndDateDisabled(false);
    document.getElementById("inputEndDate").disabled = inputEndDateDisabled;
  }
*/
  function changeEditable() {
    if (
      inputPhoneDisabled === true &&
      inputGoal1Disabled === true &&
      inputGoal2Disabled === true &&
      inputGoal3Disabled === true &&
      inputStartDateDisabled === true &&
      inputEndDateDisabled === true
    ) {
      setInputPhoneDisabled(false);
      document.getElementById("inputPhone").disabled = inputPhoneDisabled;

      setInputGoal1Disabled(false);
      document.getElementById("inputGoal1").disabled = inputGoal1Disabled;

      setInputGoal2Disabled(false);
      document.getElementById("inputGoal2").disabled = inputGoal2Disabled;

      setInputGoal3Disabled(false);
      document.getElementById("inputGoal3").disabled = inputGoal3Disabled;

      setInputStartDateDisabled(false);
      document.getElementById("inputStartDate").disabled =
        inputStartDateDisabled;

      setInputEndDateDisabled(false);
      document.getElementById("inputEndDate").disabled = inputEndDateDisabled;

      document.getElementById("update-button").style.display = "block";
      document.getElementById("save-button").style.display = "none";
      document.getElementById("cancel-button").style.display = "none";
    } else {
      setInputPhoneDisabled(true);
      document.getElementById("inputPhone").disabled = inputPhoneDisabled;

      setInputGoal1Disabled(true);
      document.getElementById("inputGoal1").disabled = inputGoal1Disabled;

      setInputGoal2Disabled(true);
      document.getElementById("inputGoal2").disabled = inputGoal2Disabled;

      setInputGoal3Disabled(true);
      document.getElementById("inputGoal3").disabled = inputGoal3Disabled;

      setInputStartDateDisabled(true);
      document.getElementById("inputStartDate").disabled =
        inputStartDateDisabled;

      setInputEndDateDisabled(true);
      document.getElementById("inputEndDate").disabled = inputEndDateDisabled;

      document.getElementById("save-button").style.display = "block";
      document.getElementById("update-button").style.display = "none";
      document.getElementById("cancel-button").style.display = "block";
    }
  }

  function toDisabled() {
    setInputPhoneDisabled(true);
    document.getElementById("inputPhone").disabled = inputPhoneDisabled;

    setInputGoal1Disabled(false);
    document.getElementById("inputGoal1").disabled = inputGoal1Disabled;

    setInputGoal2Disabled(false);
    document.getElementById("inputGoal2").disabled = inputGoal2Disabled;

    setInputGoal3Disabled(false);
    document.getElementById("inputGoal3").disabled = inputGoal3Disabled;

    setInputStartDateDisabled(false);
    document.getElementById("inputStartDate").disabled = inputStartDateDisabled;

    setInputEndDateDisabled(false);
    document.getElementById("inputEndDate").disabled = inputEndDateDisabled;
  }

  function changemobile(event) {
    setDetailsPatMobile(event.target.value);
  }

  function changeGoal1(event) {
    setDetailsPatGoal1(event.target.value);
  }

  function changeGoal2(event) {
    setDetailsPatGoal2(event.target.value);
  }

  function changeGoal3(event) {
    setDetailsPatGoal3(event.target.value);
  }

  function changeStartDate(event) {
    setDetailsPatStartDate(event.target.value);
  }
  function changeEndDate(event) {
    setDetailsPatEndDate(event.target.value);
  }

  function updateDetails(event) {
    var patientToUpdate = event.target.value;

    const config = {
      method: "POST",
      url: /*"/updatepatientdetails"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/updatepatientdetails",
      data: {
        patientToUpdate,
        detailsPatMobile,
        detailsPatGoal1,
        detailsPatGoal2,
        detailsPatGoal3,
      },
    };
    axios(config).then((result) => {
      console.log("patient data: ", result.data);
      if (result.data === "ok") {
        document.getElementsByClassName("addResultGood")[0].style.display =
          "block";
        setTimeout(function hideaddResultGood() {
          document.getElementsByClassName("addResultGood")[0].style.display =
            "none";
        }, 2000);
      } else {
        document.getElementsByClassName("addResultBad")[0].style.display =
          "block";
        setTimeout(function hideaddResultBad() {
          document.getElementsByClassName("addResultBad")[0].style.display =
            "none";
        }, 2000);
      }
    });

    if (
      inputPhoneDisabled === true &&
      inputGoal1Disabled === true &&
      inputGoal2Disabled === true &&
      inputGoal3Disabled === true &&
      inputStartDateDisabled === true &&
      inputEndDateDisabled === true
    ) {
      setInputPhoneDisabled(false);
      document.getElementById("inputPhone").disabled = inputPhoneDisabled;

      setInputGoal1Disabled(false);
      document.getElementById("inputGoal1").disabled = inputGoal1Disabled;

      setInputGoal2Disabled(false);
      document.getElementById("inputGoal2").disabled = inputGoal2Disabled;

      setInputGoal3Disabled(false);
      document.getElementById("inputGoal3").disabled = inputGoal3Disabled;

      setInputStartDateDisabled(false);
      document.getElementById("inputStartDate").disabled =
        inputStartDateDisabled;

      setInputEndDateDisabled(false);
      document.getElementById("inputEndDate").disabled = inputEndDateDisabled;

      document.getElementById("update-button").style.display = "block";
      document.getElementById("save-button").style.display = "none";
      document.getElementById("cancel-button").style.display = "none";
    } else {
      setInputPhoneDisabled(true);
      document.getElementById("inputPhone").disabled = inputPhoneDisabled;

      setInputGoal1Disabled(true);
      document.getElementById("inputGoal1").disabled = inputGoal1Disabled;

      setInputGoal2Disabled(true);
      document.getElementById("inputGoal2").disabled = inputGoal2Disabled;

      setInputGoal3Disabled(true);
      document.getElementById("inputGoal3").disabled = inputGoal3Disabled;

      setInputStartDateDisabled(true);
      document.getElementById("inputStartDate").disabled =
        inputStartDateDisabled;

      setInputEndDateDisabled(true);
      document.getElementById("inputEndDate").disabled = inputEndDateDisabled;

      document.getElementById("save-button").style.display = "block";
      document.getElementById("update-button").style.display = "none";
      document.getElementById("cancel-button").style.display = "block";
    }

    //document.getElementById("update-button").style.display = "block";
    //document.getElementById("save-button").style.display = "none";
    //document.getElementById("cancel-button").style.display = "none";
  }

  function openConfirmModal(event) {
    console.log(
      "del confrm box... ",
      event.target.parentNode.parentNode.nextElementSibling
    );
    var confirm = event.target.parentNode.parentNode.nextElementSibling;
    if (confirm.style.display === "flex") {
      confirm.style.display = "none";
    } else {
      confirm.style.display = "flex";
    }
  }
  function closeConfirmModal(event) {
    console.log(event.target.parentNode.parentNode.parentNode);
    var confirm = event.target.parentNode.parentNode.parentNode;
    confirm.style.display = "none";
  }

  return (
    <div className="show-patient-recruitment-details-wrapper">
      <div className="show-patient-recruitment-details">
        <label htmlFor={selectedPatient}> Patient ID</label>
        <input
          className="input_style_1"
          type="text"
          name={selectedPatient}
          placeholder={selectedPatient}
          value={selectedPatient}
          disabled
        ></input>

        <label>Patient Mobile</label>
        <input
          id="inputPhone"
          className="input_style_1"
          type="text"
          placeholder={detailsPatMobile}
          onChange={changemobile}
          value={detailsPatMobile}
          disabled
        ></input>

        <label>Patient Description</label>
        <textarea
          id="inputDescription"
          className="input_style_1"
          placeholder={detailsPatMobile}
          onChange={changemobile}
          value={detailsDescription}
          disabled
        ></textarea>

        <label>Goal 1</label>
        <input
          id="inputGoal1"
          className="input_style_1"
          type="text"
          placeholder={detailsPatGoal1}
          onChange={changeGoal1}
          value={detailsPatGoal1}
          disabled
        ></input>

        <label>Goal 2</label>
        <input
          id="inputGoal2"
          className="input_style_1"
          type="text"
          placeholder={detailsPatGoal2}
          onChange={changeGoal2}
          value={detailsPatGoal2}
          disabled
        ></input>

        <label>Goal 3</label>
        <input
          id="inputGoal3"
          className="input_style_1"
          type="text"
          placeholder={detailsPatGoal3}
          onChange={changeGoal3}
          value={detailsPatGoal3}
          disabled
        ></input>

        <label>Patient Age</label>
        <input
          id="inputAge"
          className="input_style_1"
          type="text"
          placeholder={detailsPatMobile}
          onChange={changemobile}
          value={detailsAge}
          disabled
        ></input>

        <label>Patient Gender</label>
        <select
          id="inputGender"
          className="input_style_1"
          placeholder="Gender"
          disabled
        >
          <option disabled selected value>
            {detailsGender}
          </option>
          <option value="Female">F</option>
          <option value="Male">M</option>
        </select>

        <label>Start Date</label>
        <input
          id="inputStartDate"
          className="input_style_1"
          type="text"
          placeholder={detailsPatStartDate}
          onChange={changeStartDate}
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          disabled
        ></input>

        <label>End Date</label>
        <input
          id="inputEndDate"
          className="input_style_1"
          type="text"
          placeholder={detailsPatEndDate}
          onChange={changeEndDate}
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          disabled
        ></input>

        <div className="detailsControls">
          <div className="update-save">
            <button
              className="button_blue"
              id="update-button"
              type="button"
              onClick={changeEditable}
              value={selectedPatient}
            >
              Update
            </button>
            <button
              className="button_blue"
              id="save-button"
              type="button"
              onClick={updateDetails}
              value={selectedPatient}
            >
              Save
            </button>
            <button
              className="button_blue"
              id="cancel-button"
              type="button"
              onClick={changeEditable}
              value={selectedPatient}
            >
              Cancel
            </button>
          </div>
          <div className="addResultGood">Updated</div>
          <div className="addResultBad">Something went wrong</div>

          <button className="button_blue" type="button" value={selectedPatient}>
            Print Patient Details
          </button>

          <button
            className="button_red"
            type="button"
            onClick={openConfirmModal}
            value={selectedPatient}
          >
            Delete Patient
          </button>
        </div>
      </div>

      <div className="confirm-modal">
        <div className="confirm-box">
          <p>Confirm Deletion of patient {selectedPatient}</p>
          <div className="confirm-cancel">
            <button
              className="button_red"
              id="confirm"
              onClick={deletePatient}
              value={selectedPatient}
            >
              Confirm
            </button>
            <button
              className="button_blue"
              id="cancel"
              onClick={closeConfirmModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RecruitmentDetails;
