import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/stopfalls-styles.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

function Stopfalls() {
  const [allTopics, setAllTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState();

  const [overallDesc, setOverallDesc] = useState([]);
  const [subHeading_1, setSubHeading_1] = useState([]);
  const [subHeading_2, setSubHeading_2] = useState([]);
  const [subHeading_3, setSubHeading_3] = useState([]);
  const [subHeading_4, setSubHeading_4] = useState([]);
  const [subHeading_5, setSubHeading_5] = useState([]);
  const [subHeading_6, setSubHeading_6] = useState([]);
  const [textImageSec, setTextImageSec] = useState([]);
  const [textImageSec2, setTextImageSec2] = useState([]);
  const [textImageSec3, setTextImageSec3] = useState([]);
  const [textImageSec4, setTextImageSec4] = useState([]);
  const [textImageSec5, setTextImageSec5] = useState([]);
  const [textImageSec6, setTextImageSec6] = useState([]);
  const [currentIcon, setCurrentIcon] = useState([]);

  const [currentSlideImage, setCurrentSlideImage] = useState([]);
  const [currentSlideText, setCurrentSlideText] = useState();

  useEffect(() => {
    axios
      .get(
        /*"/GETtopics"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/GETtopics"
      )
      .then(function (res) {
        setAllTopics(res.data);

        //set the default state to the first topic name
        setSelectedTopic(res.data[0].TopicName);
        setCurrentIcon(res.data[0].TopicIcon);
        setOverallDesc(res.data[0].OverallDesc);
        setSubHeading_1(res.data[0].SubHeading_1);
        setSubHeading_2(res.data[0].SubHeading_2);
        setSubHeading_3(res.data[0].SubHeading_3);
        setSubHeading_4(res.data[0].SubHeading_4);
        setSubHeading_5(res.data[0].SubHeading_5);
        setSubHeading_6(res.data[0].SubHeading_6);
        setTextImageSec(res.data[0].TexImageWrap1);
        setTextImageSec2(res.data[0].TexImageWrap2);
        setTextImageSec3(res.data[0].TexImageWrap3);
        setTextImageSec4(res.data[0].TexImageWrap4);
        setTextImageSec5(res.data[0].TexImageWrap5);
        setTextImageSec6(res.data[0].TexImageWrap6);

        res.data[0].TexImageWrap1.map((item, index) => {
          setTimeout(() => {
            setCurrentSlideImage(item.image);
            setCurrentSlideText(item.text);
          }, index * 4000);
          return 1;
        });
      });
  }, []);

  function listenTopicName(event) {
    var index = event.target.dataset.mykey;

    setSelectedTopic(allTopics[index].TopicName);
    setCurrentIcon(allTopics[index].TopicIcon);
    setOverallDesc(allTopics[index].OverallDesc);
    setSubHeading_1(allTopics[index].SubHeading_1);
    setSubHeading_2(allTopics[index].SubHeading_2);
    setSubHeading_3(allTopics[index].SubHeading_3);
    setSubHeading_4(allTopics[index].SubHeading_4);
    setSubHeading_5(allTopics[index].SubHeading_5);
    setSubHeading_6(allTopics[index].SubHeading_6);
    setTextImageSec(allTopics[index].TexImageWrap1);
    setTextImageSec2(allTopics[index].TexImageWrap2);
    setTextImageSec3(allTopics[index].TexImageWrap3);
    setTextImageSec4(allTopics[index].TexImageWrap4);
    setTextImageSec5(allTopics[index].TexImageWrap5);
    setTextImageSec6(allTopics[index].TexImageWrap6);

    //close the menu when a new topic is selected and screen width = mobile dvice
    var target = event.target.parentNode.parentNode;
    console.log(target);
    var x = window.matchMedia("(max-width: 700px)");
    if (x.matches) {
      target.style.display = "none";
    }

    //scroll to top when a new topic is selected
    window.scrollTo(0, 0);
  }

  function burgerToggle(event) {
    var target = event.target.nextElementSibling;
    console.log(target);
    if (target.style.display === "flex") {
      target.style.display = "none";
    } else {
      target.style.display = "flex";
    }
  }

  return (
    <div>
      <Header />
      <div className="stopfalls-main-container">
        <div className="stopfalls-left-container" id="left-contianer">
          <span className="burger" onClick={burgerToggle}>
            Topics
          </span>
          <div className="topics-wrapper">
            <form action="/">
              <button className="backToHome"> Back to home</button>
            </form>
            <div className="topics-container">
              {allTopics.map((item, index) => (
                <span
                  className="topics"
                  key={index}
                  data-mykey={index}
                  onClick={listenTopicName}
                >
                  <img src={item.TopicIcon} alt="1"></img>
                  {item.TopicName}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="stopfalls-right-container">
          <div className="content-container">
            <p className="topic-heading">
              <img src={currentIcon} alt="icon"></img>
              {selectedTopic}
            </p>
            <div className="temp-video">
              <div className="slide-image">
                <img src={currentSlideImage} alt="image1"></img>
              </div>
              <div className="slide-text">
                <span>{currentSlideText}</span>
              </div>
            </div>

            {/*Overall Text (if any) */}
            <div className="overall-text">
              <ul>
                {overallDesc.map((item, index) => (
                  <li key={index}> {item}</li>
                ))}
              </ul>
            </div>
            {/*Sub Heading-1 (if any)*/}
            <div className="sub-heading">{subHeading_1}</div>

            {/*text image sections*/}
            <div className="imageAndText-container-1">
              {textImageSec.map((item, index) => (
                <div
                  key={index}
                  data-mykey={index}
                  className="imageTextWrapper"
                >
                  <div className="image-wrapper">
                    <img src={item.image} alt={index}></img>
                  </div>

                  <div className="text-heading">{item.text_heading}</div>

                  <div className="text-wrapper">
                    <ul>
                      {item.text.map((text) => (
                        <li>{text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {/*Sub Heading-2 (if any)*/}
            <div className="sub-heading">{subHeading_2}</div>

            {/*text image-2 sections*/}
            <div className="imageAndText-container-1">
              {textImageSec2.map((item, index) => (
                <div
                  key={index}
                  data-mykey={index}
                  className="imageTextWrapper"
                >
                  <div className="image-wrapper">
                    <img src={item.image} alt={index}></img>
                  </div>

                  <div className="text-wrapper">
                    <ul>
                      {item.text.map((text) => (
                        <li>{text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {/*Sub Heading-3 (if any)*/}
            <div className="sub-heading">{subHeading_3}</div>

            {/*text image-3 sections*/}
            <div className="imageAndText-container-1">
              {textImageSec3.map((item, index) => (
                <div
                  key={index}
                  data-mykey={index}
                  className="imageTextWrapper"
                >
                  <div className="image-wrapper">
                    <img src={item.image} alt={index}></img>
                  </div>

                  <div className="text-wrapper">
                    <ul>
                      {item.text.map((text) => (
                        <li>{text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {/*Sub Heading-4 (if any)*/}
            <div className="sub-heading">{subHeading_4}</div>

            {/*text image-4 sections*/}
            <div className="imageAndText-container-1">
              {textImageSec4.map((item, index) => (
                <div
                  key={index}
                  data-mykey={index}
                  className="imageTextWrapper"
                >
                  <div className="image-wrapper">
                    <img src={item.image} alt={index}></img>
                  </div>

                  <div className="text-wrapper">
                    <ul>
                      {item.text.map((text) => (
                        <li>{text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {/*Sub Heading-5 (if any)*/}
            <div className="sub-heading">{subHeading_5}</div>

            {/*text image-5 sections*/}
            <div className="imageAndText-container-1">
              {textImageSec5.map((item, index) => (
                <div
                  key={index}
                  data-mykey={index}
                  className="imageTextWrapper"
                >
                  <div className="image-wrapper">
                    <img src={item.image} alt={index}></img>
                  </div>

                  <div className="text-wrapper">
                    <ul>
                      {item.text.map((text) => (
                        <li>{text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {/*Sub Heading-6 (if any)*/}
            <div className="sub-heading">{subHeading_6}</div>

            {/*text image-6 sections*/}
            <div className="imageAndText-container-1">
              {textImageSec6.map((item, index) => (
                <div
                  key={index}
                  data-mykey={index}
                  className="imageTextWrapper"
                >
                  <div className="image-wrapper">
                    <img src={item.image} alt={index}></img>
                  </div>

                  <div className="text-wrapper">
                    <ul>
                      {item.text.map((text) => (
                        <li>{text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default Stopfalls;
