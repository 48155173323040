import { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import PatientDashboard from "./PatientDashboard";
import RecruitmentDetails from "../components/recruitmentdetails";
import Recommendations from "../components/recommendations";
import Exercises from "../components/exercises";
import Diary from "../components/diary";
import AddPatient from "../components/addpatient";
import "../styles/patient-style.css";
import axios from "axios";
import { useEffect } from "react";

function PatientMain() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  var currentDate = year + "-" + month + "-" + day;
  //console.log("current====== ", currentDate);

  const date6 = new Date();
  date6.setMonth(date6.getMonth() + 3);
  console.log("futureeee====== ", date6.getMonth() + 1);
  const futureyear = date6.getFullYear();
  const futureMonth = String(date6.getMonth() + 1).padStart(2, "0");
  const futureday = String(date6.getDate()).padStart(2, "0");
  var futureDate = futureyear + "-" + futureMonth + "-" + futureday;

  const [selectedPatientID, setSelectedPatientID] = useState("");
  const [patientStartDate, setPatientStartDate] = useState(currentDate);
  const [patientEndDate, setPatientEndDate] = useState(futureDate);

  const [patientID, setPatientID] = useState("");
  const [patientMobileNumber, setPatientMobileNumebr] = useState("");
  const [patientDescription, setPatientDescription] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientGoal1, setPatientGoal1] = useState("");
  const [patientGoal2, setPatientGoal2] = useState("");
  const [patientGoal3, setPatientGoal3] = useState("");

  const [errorFlag, setErrorFlag] = useState(false);

  const [flagPatientDashboard, setFlagPatientDashboard] = useState("false");

  const [btnAddPatient, setbtnAddPatient] = useState("false");

  const [displayPatientMobile, setDisplayPatientMobile] = useState("select");

  const [patientList, setPatientList] = useState([]);

  const [addStatus, setAddStatus] = useState("");

  useEffect(() => {
    axios
      .get(
        /*"/getpatient"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/getpatient"
      )
      .then(function (res) {
        console.log("recieved patient list: ", res.data);
        setPatientList(res.data);
      });
  }, []);
  console.log(patientList);

  function listenPatientID(event) {
    console.log("slected id ", event.target.value);
    var value = event.target.value;
    setErrorFlag(false);
    if (value.length === 0) {
      document.getElementById("hospitalId").className = "errorField";
      document.getElementsByClassName("errorMsg")[0].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("hospitalId").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[0].style.display = "none";
      setErrorFlag(false);
    }
    setPatientID(event.target.value);
  }

  function listenPatientMobileNumber(event) {
    console.log(event.target.value);
    var value = event.target.value;
    const regex = /\d/;
    if (value.length === 0) {
      document.getElementById("patPhoneNumber").className = "errorField";
      document.getElementsByClassName("errorMsg")[1].style.display =
        "inline-block";
      document.getElementsByClassName("errorMsg")[2].style.display = "none";
      setErrorFlag(true);
    } else if (value.length !== 10 || !regex.test(value)) {
      document.getElementById("patPhoneNumber").className = "errorField";
      document.getElementsByClassName("errorMsg")[1].style.display = "none";
      document.getElementsByClassName("errorMsg")[2].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("patPhoneNumber").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[1].style.display = "none";
      document.getElementsByClassName("errorMsg")[2].style.display = "none";
      setErrorFlag(false);
    }
    setPatientMobileNumebr(event.target.value);
  }

  function listenpatientDescription(event) {
    console.log(event.target.value);
    setPatientDescription(event.target.value);
  }
  function listenPatientAge(event) {
    console.log("dsdsdsdss", document.getElementsByClassName("errorMsg")[4]);
    var value = event.target.value;
    const regex = /\d/;
    if (value.length === 0) {
      document.getElementById("patAge").className = "errorField";
      document.getElementsByClassName("errorMsg")[3].style.display =
        "inline-block";
      document.getElementsByClassName("errorMsg")[4].style.display = "none";
      setErrorFlag(true);
    } else if (!regex.test(value)) {
      document.getElementById("patAge").className = "errorField";
      document.getElementsByClassName("errorMsg")[3].style.display = "none";
      document.getElementsByClassName("errorMsg")[4].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      console.log("Age is not empty");
      document.getElementById("patAge").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[3].style.display = "none";
      document.getElementsByClassName("errorMsg")[4].style.display = "none";
      setErrorFlag(false);
    }
    setPatientAge(event.target.value);
  }
  function listenPatientGender(event) {
    console.log(event.target.value);
    setPatientGender(event.target.value);
  }
  function listenPatientGoal1(event) {
    console.log(event.target.value);
    setPatientGoal1(event.target.value);
  }
  function listenPatientGoal2(event) {
    console.log(event.target.value);
    setPatientGoal2(event.target.value);
  }
  function listenPatientGoal3(event) {
    console.log(event.target.value);
    setPatientGoal3(event.target.value);
  }
  function listenStartDate(event) {
    setPatientStartDate(event.target.value);
  }
  function listenEndDate(event) {
    setPatientEndDate(event.target.value);
  }

  function addPatientButton() {
    setbtnAddPatient("true");
    setFlagPatientDashboard("false");
  }

  console.log(document.getElementById("addResultGoodd"));

  async function addNewPatient(event) {
    event.preventDefault();
    if (errorFlag === true) {
      console.log("cant submit!");
      return;
    }
    const config = {
      method: "POST",
      url: /*"/addpatient"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/addpatient",
      data: {
        patientID,
        patientMobileNumber,
        patientDescription,
        patientAge,
        patientGender,
        patientGoal1,
        patientGoal2,
        patientGoal3,
        patientStartDate,
        patientEndDate,
      },
    };

    axios(config)
      .then((result) => {
        console.log("patient data: ", result.data);

        if (result.data === "Participant Added") {
          setAddStatus("Participant Added");
          document.getElementsByClassName("addResultGoodd")[0].style.display =
            "block";
          setTimeout(function hideaddResultGood() {
            document.getElementsByClassName("addResultGoodd")[0].style.display =
              "none";
          }, 2000);
          setPatientList([...patientList, patientID]);
          setPatientID("");
          setPatientMobileNumebr("");

          setPatientAge("");
          setPatientGender("");
          setPatientStartDate(currentDate);
          setPatientEndDate(futureDate);
        } else {
          setAddStatus("ID already exists!");
          document.getElementsByClassName("addResultBadd")[0].style.display =
            "block";
          setTimeout(function hideaddResultBad() {
            document.getElementsByClassName("addResultBadd")[0].style.display =
              "none";
          }, 2000);
        }
      })
      .then(() => {
        //update the state to show patient list
        //window.location.reload(true);
        //setPatientList([...patientList, patientID]);
      });
  }

  function deletePatient() {
    console.log("to delete: ", selectedPatientID);
  }

  function listenPatient(event) {
    console.log(event.target.value);
    setSelectedPatientID(event.target.value);
    setDisplayPatientMobile(event.target.value);
    setFlagPatientDashboard("true");
    setbtnAddPatient("false");
    var x = window.matchMedia("(max-width: 946px)");
    if (x.matches) {
      document.getElementById("patients-list-container").style.display = "none";
    }

    console.log("selected isss ", selectedPatientID);
  }

  function toggle(event) {
    console.log(event.target.nextElementSibling);
    var patlist = event.target.nextElementSibling;
    if (patlist.style.display === "block") {
      patlist.style.display = "none";
    } else {
      patlist.style.display = "block";
    }
  }

  return (
    <div>
      <Header />
      <div className="patients-main-container">
        <div className="patients-left-container">
          <div className="search-add">
            <button className="addpatientButton" onClick={addPatientButton}>
              Add Patient +
            </button>
          </div>

          <div className="toggle" onClick={toggle}>
            Patient List
          </div>
          <div id="patients-list-container" className="patients-list-container">
            {patientList.map((patients, pateintkey) => (
              <div
                key={pateintkey}
                data-mykey={pateintkey}
                className="patient-display-list"
              >
                <button
                  className={
                    patients === selectedPatientID ? "active" : "patientBtn"
                  }
                  onClick={listenPatient}
                  value={patients}
                >
                  {patients}
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="patients-right-container">
          {flagPatientDashboard === "true" ? (
            <PatientDashboard selectedpatientid={selectedPatientID} />
          ) : (
            <AddPatient
              addnewpatient={addNewPatient}
              addStatus={addStatus}
              listenpatientID={listenPatientID}
              listenpatientmobilenumber={listenPatientMobileNumber}
              listenpatientdescription={listenpatientDescription}
              listenpatientage={listenPatientAge}
              listenpatientgender={listenPatientGender}
              listenpatientGoal1={listenPatientGoal1}
              listenpatientGoal2={listenPatientGoal2}
              listenpatientGoal3={listenPatientGoal3}
              listenstartdate={listenStartDate}
              listenenddate={listenEndDate}
              patientid={patientID}
              patientmobilenumber={patientMobileNumber}
              patientdescription={patientDescription}
              patientage={patientAge}
              patientgender={patientGender}
              startdate={patientStartDate}
              enddate={patientEndDate}
            />
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default PatientMain;
