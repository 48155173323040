import Header from "../components/header";
import Footer from "../components/footer";
import woman from "../assets/crop ajji transparent.png";
import man from "../assets/crop brown shirt ajja.png";
import collage from "../assets/collage.png";
import icmrlogo from "../assets/icmrlogo.webp";

function Home() {
  return (
    <div className="main-wrapper">
      <Header />
      <div className="home-page-container">
        <div className="segment-1">
          {/* 
          <span
            id={props.taglineID}
            contentEditable={props.taglineEditValue}
            onClick={props.triggerChangeTagLineEditValue}
            onChange={props.monitorchanges}
          >
            {tagLineValue}
          </span>
          */}
          <span className="heading">
            A GUIDE TO FALL PREVENTION AND HEALTHY AGEING
          </span>
          <p id="stay-safe">Stay safe, active, and independent !</p>

          <div className="collage">
            <img src={collage} alt="" />
          </div>
          <span className="heading">Healthy ageing and falls</span>
          <p id="itro-id">
            Healthy ageing is a means to support older adults in maintaining
            their functional abilities and improving their lives. Falls and
            fall-related injuries are major problems in older adults. This
            project provides information on healthy lifestyle practices, safety
            behaviours, exercises to improve balance and strength and
            recommendations to make homes safer.
            <br></br>
          </p>
        </div>
        <div></div>
        <div className="segment-2">
          <div className="topic-button-container">
            <img className="woman" src={woman} alt="w"></img>
            <img className="man" src={man} alt="m"></img>
            <form action="/Stopfalls">
              <button className="topic-button">Stop Falls</button>
            </form>
          </div>
        </div>

        <div className="segment-3">
          <div className="segment-3-wrapper">
            <div className="team-container">
              <span id="team-heading">Project Team</span>
              <hr></hr>
              <div className="team-details-container">
                <div className="cards">
                  <div className="inner-wrapper">
                    <span className="member-name">
                      Dr. Sebestina Anita Dsouza
                    </span>
                    <span className="designation">Professor</span>
                    <span className="email-heading">Email</span>
                    <span className="email-desc">
                      sebestina.dsouza@manipal.edu
                    </span>
                    <span className="role-name">Role</span>
                    <span className="role-desc">
                      Principal Investigator for the project
                    </span>
                    <span className="department">
                      Dept. of Occupational Therapy <br></br> Coordinator for
                      Centre for studies on Healthy Aging, Manipal College of
                      Health Professions, Manipal Academy of Higher Education
                    </span>
                    <span className="organization"></span>
                  </div>
                </div>
                <div className="cards">
                  <div className="inner-wrapper">
                    <span className="member-name">
                      Ms. Rujutha D.Ksheerasagar
                    </span>
                    <span className="designation">
                      Junior Research Fellow, PhD Scholar
                    </span>
                    <span className="email-heading">Email</span>
                    <span className="email-desc">
                      rujutha.dk@learner.manipal.edu
                    </span>
                    <span className="role-name">Role</span>
                    <span className="role-desc"></span>
                    <span className="department">
                      Dept. of Occupational Therapy, Manipal College of Health
                      Professions, Manipal Academy of Higher Education
                    </span>
                    <span className="organization"></span>
                  </div>
                </div>
                <div className="cards">
                  <div className="inner-wrapper">
                    <span className="member-name">Rohini R Rao</span>
                    <span className="designation">
                      Asst. Professor - Senior Scale
                    </span>
                    <span className="email-heading">Email</span>
                    <span className="email-desc">rohini.rao@manipal.edu</span>
                    <span className="role-name">Role</span>
                    <span className="role-desc">
                      Design and Development of Technology based Intervention
                      for Fall Prevention
                    </span>
                    <span className="department">
                      Dept. of Data Science and Computer Applications Manipal
                      Institute of Technology
                    </span>
                    <span className="organization"></span>
                  </div>
                </div>
                <div className="cards">
                  <div className="inner-wrapper">
                    <span className="member-name"></span>
                    <span className="designation"></span>
                    <span className="email-heading">Email</span>
                    <span className="email-desc"></span>
                    <span className="role-name">Role</span>
                    <span className="role-desc"></span>
                    <span className="department"></span>
                    <span className="organization"></span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="seperator"></hr>
            <div className="funds-details">
              <p>Project funded by</p>
              <p>Department of Health Research, ICMR, India</p>
              <div className="icmr-logo-container">
                <img className="icmr-logo" src={icmrlogo} alt="ICMR"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Home;
