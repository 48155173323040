import { Navigate } from "react-router-dom";

const Protected = ({ isLoggedInFinal, children }) => {
  console.log("insdie protected>>>>>>>, ", isLoggedInFinal);
  if (!isLoggedInFinal && sessionStorage.getItem("username") === null) {
    return <Navigate to="/Admin" replace />;
  }
  return children;
};
export default Protected;
