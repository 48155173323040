import { Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Cms from "./pages/Cms";
import PatientMain from "./pages/PatientMain";
import Stopfalls from "./pages/Stopfalls";
import Help from "./pages/Help";
import Protected from "./pages/Protected";
import Dashboard from "./pages/dashboard";

import { LoginContext } from "./contexts/LoginContext";

//const LoginContext = createContext();

function App() {
  const [isLoginFinal, setIsLoginFinal] = useState(false);
  //const { isLoginFinal, setIsLoginFinal } = useContext(LoginContext);
  /*
  function getLoginVal(data) {
    console.log("ajhdlahsldhalkdhla>>>>>>>>", data);
    setIsLoginFinal(data);
  }
*/

  console.log("insdie main>>>>>>>, ", isLoginFinal);
  console.log(
    "insdie main session >>>>>>>, ",
    sessionStorage.getItem("username")
  );
  return (
    <div>
      <LoginContext.Provider value={{ isLoginFinal, setIsLoginFinal }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/StopFalls" element={<Stopfalls />} />
          <Route path="/help" element={<Help />} />
          <Route path="/Admin" element={<Admin />} />
          <Route path="/Cms" element={<Cms />} />
          <Route
            exact
            path="/"
            element={
              <Navigate push to={"/privacyPolicy_SeniorSaathi.html"}></Navigate>
            }
          ></Route>

          <Route
            path="/Dashboard"
            element={
              <Protected isLoggedInFinal={isLoginFinal}>
                <Dashboard />
              </Protected>
            }
          />

          <Route
            path="/PatientMain"
            element={
              <Protected isLoggedInFinal={isLoginFinal}>
                <PatientMain />
              </Protected>
            }
          />
        </Routes>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
