import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/admin-style.css";
import "../styles/global-style.css";
import { useContext } from "react";
import { LoginContext } from "../contexts/LoginContext";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Admin() {
  //const [isLogin, setIsLogin] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { isLoginFinal, setIsLoginFinal } = useContext(LoginContext);
  console.log("insdie wizard>>>>>>>, ", isLoginFinal);

  function listenUserName(event) {
    setUserName(event.target.value);
  }

  function listenUserPassword(event) {
    setUserPassword(event.target.value);
  }

  let navigate = useNavigate();
  async function handleSubmit(event) {
    event.preventDefault();

    if (
      document.getElementById("usernameinput").value.length === 0 ||
      document.getElementById("passwordinput").value.length === 0
    ) {
      setErrorMessage("Fields cant not be blank");
      return;
    }

    // (using axios) sending the username and password to backend and getting the response
    const config = {
      method: "POST",
      url: "/admin",
      data: { userName, userPassword },
    };

    axios(config).then((result) => {
      console.log(result.data.message);
      console.log(result.data.username);
      console.log(result.status);
      if (result.data.username === null) {
        setIsLoginFinal(false);
        setErrorMessage("Username and/ or Password does not match!");
      } else {
        setIsLoginFinal(true);
        sessionStorage.setItem("username", userName);
        navigate("/Dashboard");
      }
    });
  }

  return (
    <div className="admin-wrapper">
      <Header />
      <div className="admin-container">
        <div className="admin-left">Login</div>
        <div className="admin-right">
          <div className="admin-login">
            <div className="error-box">
              <p>{errorMessage}</p>
            </div>
            <input
              id="usernameinput"
              onChange={listenUserName}
              type="text"
              placeholder="User Name"
              value={userName}
            />
            <input
              id="passwordinput"
              onChange={listenUserPassword}
              type="text"
              placeholder="Password"
              value={userPassword}
            />
            <button onClick={handleSubmit} type="submit">
              Login
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Admin;
