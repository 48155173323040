import axios from "axios";
import { useEffect, useState } from "react";

function Recommendations(props) {
  var selectedPatient = [props.selectedpatientid];

  const [refreshState, setRefreshState] = useState(0);

  const [recommendationSet, setRecommendationSet] = useState(1);
  const [allRecommendationSet, setAllRecommendationSet] = useState([]);
  const [selectedSet, setSelectedSet] = useState();

  const [allRecommendations, setAllRecommendations] = useState([]);
  const [assignedRecommendations, setAssignedRecommendations] = useState([]);
  const [recommIdValue, setRecommIdValue] = useState([]);
  const [toRemoveRecommIdValue, setToRemoveRecommIdValue] = useState([]);

  const [recommStartDate, setRecommStartDate] = useState("");
  const [recommEndDate, setRecommEndDate] = useState("");

  const [highestSet, setHighestSet] = useState();

  const [flag, setFlag] = useState(false);

  var len = allRecommendationSet.length;

  //get assigned recommendations form the backend
  useEffect(() => {
    console.log("current set..... ", selectedSet);

    axios
      .get(
        /*"/getrecommendations"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/getrecommendations",
        {
          params: {
            selectedPatientID: props.selectedpatientid,
            selectedSet: selectedSet,
          },
        }
      )
      .then(function (res) {
        console.log("got from back: ", res.data[4]);
        setAllRecommendationSet(res.data[0]);
        setRecommStartDate(String(res.data[1]).slice(0, 10));
        setRecommEndDate(String(res.data[2]).slice(0, 10));
        setAllRecommendations(res.data[3]);
        setAssignedRecommendations(res.data[4]);
        setRecommendationSet(res.data[0].length);

        if (flag === false) {
          if (res.data[0].length === 0) {
            setSelectedSet(0);
          } else {
            setSelectedSet(Math.max(...res.data[0]));
            console.log("max max max.... ", Math.max(...res.data[0]));
          }
        }
      })
      .then(() => {
        setFlag(false);
      });
  }, [props.selectedpatientid, selectedSet]);

  function selectRecommSet(event) {
    console.log("selected set>>>>> ", event.target.dataset.value);
    setFlag(true);
    setSelectedSet(event.target.dataset.value);
  }

  function listenRecommendationValue(event) {
    if (event.target.checked) {
      //add checked items to the array
      setRecommIdValue((prev) => {
        return [...prev, event.target.value];
      });
    } else {
      //Remove unchecked items from the array
      const index = recommIdValue.indexOf(event.target.value);
      recommIdValue.splice(index, 1);
    }
  }

  function listenToRemoveRecommendationValue(event) {
    if (event.target.checked) {
      setToRemoveRecommIdValue((toRemovePrev) => {
        return [...toRemovePrev, event.target.value];
      });
    } else {
      const indexToRemove = toRemoveRecommIdValue.indexOf(event.target.value);
      toRemoveRecommIdValue.splice(indexToRemove, 1);
    }
  }

  function listenRecommStartDate(event) {
    setRecommStartDate(event.target.value);
  }

  function listenRecommEndDate(event) {
    setRecommEndDate(event.target.value);
  }

  function addrecommendationset(event) {
    event.preventDefault();
    setRecommendationSet(len + 1);
    console.log("current set >>>>>>>>> ", recommendationSet);
    const config = {
      method: "POST",
      url: /*"/postrecommendationset"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/postrecommendationset",
      data: {
        selectedPatient,
        recommendationSet,
        recommStartDate,
        recommEndDate,
      },
    };
    axios(config)
      .then((result) => {
        console.log("sent data is:..", result.data);
        setAllRecommendationSet([...allRecommendationSet, recommendationSet]);
      })
      .then(() => {
        setHighestSet(Math.max(...allRecommendationSet));
        console.log(
          "><><><>><<",
          event.target.parentNode.parentNode.parentNode
        );
        var confirm = event.target.parentNode.parentNode.parentNode;
        confirm.style.display = "none";
      });
  }
  console.log("highest set/////////////////////////////////// ", highestSet);

  //Sending recommendations to backend
  function sendrecommendations(event) {
    event.preventDefault();
    console.log("while sending ", selectedPatient);

    const config = {
      method: "POST",
      url: /*"/addrecommendations"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/addrecommendations",
      data: {
        selectedPatient,
        selectedSet,
        recommIdValue,
      },
    };

    axios(config).then((result) => {
      console.log("sent data is:..", result.data);
      if (result.data === "ok") {
        document.getElementsByClassName("addResultGood")[0].style.display =
          "block";
        setTimeout(function hideaddResultGood() {
          document.getElementsByClassName("addResultGood")[0].style.display =
            "none";
        }, 2000);
      } else {
        document.getElementsByClassName("addResultBad")[0].style.display =
          "block";
        setTimeout(function hideaddResultBad() {
          document.getElementsByClassName("addResultBad")[0].style.display =
            "none";
        }, 2000);
      }
    });
  }

  //Sending to-be-removed-recommendations to backend
  function removerecommendations(event) {
    event.preventDefault();
    const config = {
      method: "POST",
      url: /*"/removerecommendations"*/ "https://asia-south1-projectseniorsaathilive.cloudfunctions.net/app/removerecommendations",
      data: {
        selectedPatient,
        selectedSet,
        toRemoveRecommIdValue,
      },
    };

    axios(config).then((result) => {
      console.log("sent data is:..", result.data);
    });
  }

  //Collapsable menu options (recommendations)
  function toggle(event) {
    var content = event.target.parentNode.nextElementSibling;
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  function openConfirmModalCreateSet(event) {
    console.log(
      "confirm model open ,.,.,.,,.,.",
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[3]
    );
    var confirm =
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[3];

    if (confirm.style.display === "flex") {
      confirm.style.display = "none";
    } else {
      confirm.style.display = "flex";
    }
  }

  function openConfirmModal(event) {
    console.log(
      "confirm model open ,.,.,.,,.,.",
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[2]
    );
    var confirm =
      event.target.parentNode.parentNode.parentNode.parentNode.parentNode
        .childNodes[2];

    if (confirm.style.display === "flex") {
      confirm.style.display = "none";
    } else {
      confirm.style.display = "flex";
    }
  }

  function closeConfirmModal(event) {
    console.log(event.target.parentNode.parentNode.parentNode);
    var confirm = event.target.parentNode.parentNode.parentNode;
    confirm.style.display = "none";
  }

  function deleteRecomSet(event) {
    console.log("to delete...", event.target.value);
    var recomSetToDelete = event.target.value;

    const config = {
      method: "POST",
      url: "/deleteRecomSet",
      data: {
        selectedPatient,
        recomSetToDelete,
      },
    };

    axios(config).then((result) => {
      console.log("to be deleted..", recomSetToDelete);

      //setSelectedSet();
      //window.location.reload(true);
    });
  }

  return (
    <div className="recommendations-container-parent">
      <div className="recommendations-set-wrapper">
        <div className="recommendations-set-controls-and-title">
          <div className="dropdown-container">
            <div className="dropdown">
              <div className="toggleview">
                <span>
                  <p className="head">
                    Recommendation Set_{Number(selectedSet) + 1}
                  </p>
                </span>
                <span className="arrow down"></span>
                <div className="dropdown-click-surface" onClick={toggle}></div>
              </div>

              <div className="content" id="content-sets">
                {allRecommendationSet.map((item) => (
                  <div className="options">
                    <p data-value={item} onClick={selectRecommSet}>
                      Recommendation Set_{parseInt(item) + 1}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="recommendations-set-controls">
            <div className="buttons-container">
              <button
                className="button_blue"
                id="add"
                onClick={openConfirmModalCreateSet}
              >
                Create Set
              </button>
              <button className="button_blue" id="add">
                Edit Set Info
              </button>
              <button
                className="button_red"
                id="add"
                onClick={openConfirmModal}
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div className="recommendations-inner-wrapper">
          <div className="dates-container">
            <div className="input-container">
              <label htmlFor="startdate">Start Date</label>
              <input
                className="input_style_1"
                onChange={listenRecommStartDate}
                type="date"
                name="startdate"
                placeholder="date"
                value={recommStartDate}
              />
            </div>
            <div className="input-container">
              <label htmlFor="enddate">End Date</label>
              <input
                className="input_style_1"
                type="date"
                name="enddate"
                placeholder="date"
                onChange={listenRecommEndDate}
                value={recommEndDate}
              />
            </div>
          </div>
          <div className="conditions-container">
            <div className="input-container">
              <textarea
                className="input_style_1"
                placeholder="Description"
                rows="4"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="recommendations-box-wrapper">
        {/* Available Recommendations */}
        <div
          className="recommendations-box-container"
          id="available-recommendations-box-container"
        >
          <p className="head">Available Recommendations</p>
          {allRecommendations.map((item) => (
            <div className="recommendations-boxes" key={item._id}>
              <div className="toggleview">
                <span>{item.RecommendationTypeName}</span>
                <span className="arrow down"></span>
                <div className="dropdown-click-surface" onClick={toggle}></div>
              </div>
              <div className="content">
                {item.Recommendation.map((sub) => (
                  <div className="options">
                    <label htmlFor={sub.recID}>
                      <input
                        className="checkboxes"
                        id={sub.recID}
                        type="checkbox"
                        key={sub.recID}
                        onClick={listenRecommendationValue}
                        name={sub.recID}
                        value={sub.recID}
                      ></input>
                      {sub.RecDesc}
                      <span id="availableHazards">{sub.RecDescHazards}</span>
                      <br></br>

                      <span id="availableSuggestions">
                        {sub.RecDescSuggestions}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="buttons-container">
            <button
              className="button_blue"
              id="add"
              onClick={sendrecommendations}
            >
              Add
            </button>
          </div>
          <div className="addResultGood">Added</div>
          <div className="addResultBad">Something went wrong</div>
        </div>

        {/* Assigned Recommendations */}
        <div
          className="recommendations-box-container"
          id="assigned-recommendations-box-container"
        >
          <p className="head">Assigned Recommendations</p>
          {allRecommendations.map((item) => (
            <div className="recommendations-boxes">
              <div className="toggleview">
                <span>{item.RecommendationTypeName}</span>
                {/*<span className="arrow down"></span>*/}
              </div>
              <div className="assigned-content">
                {item.Recommendation.filter((x) =>
                  assignedRecommendations.includes(x.RecDesc)
                ).map((sub) => (
                  <div className="options">
                    <label htmlFor={sub.recID}>
                      <input
                        className="checkboxes"
                        id={sub.recID}
                        type="checkbox"
                        key={sub.recID}
                        onClick={listenToRemoveRecommendationValue}
                        name={sub.recID}
                        value={sub.recID}
                      ></input>
                      {sub.RecDesc}
                      <span id="availableHazards">{sub.RecDescHazards}</span>
                      <br></br>

                      <span id="availableSuggestions">
                        {sub.RecDescSuggestions}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="buttons-container">
            <button
              className="button_red"
              id="remove"
              onClick={removerecommendations}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      {/* 
      <div className="buttons-container">
        <button className="save-button" id="add" onClick={sendrecommendations}>
          Add
        </button>
        <button
          className="save-button"
          id="remove"
          onClick={removerecommendations}
        >
          Remove
        </button>
      </div>
      */}

      <div className="confirm-modal">
        <div className="confirm-box">
          <p>Confirm Deletion of Set {Number(selectedSet) + 1}</p>
          <div className="confirm-cancel">
            <button
              className="button_red"
              id="confirm"
              onClick={deleteRecomSet}
              value={selectedSet}
            >
              Confirm
            </button>
            <button
              className="button_blue"
              id="cancel"
              onClick={closeConfirmModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="confirm-modal" id="confirm-modal-create-set">
        <div className="confirm-box">
          <p>Confirm starting of a new Set</p>
          <div className="confirm-cancel">
            <button
              className="button_red"
              id="confirm"
              onClick={addrecommendationset}
            >
              Confirm
            </button>
            <button
              className="button_blue"
              id="cancel"
              onClick={closeConfirmModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Recommendations;
