import Header from "../components/header";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
function Dashboard() {
  let navigate = useNavigate();

  const [currentTherapist, setCurrentTherapist] = useState("");
  useEffect(() => {
    setCurrentTherapist(sessionStorage.getItem("username"));
  }, []);

  function openPtientMain() {
    navigate("/PatientMain");
  }

  function openCMS() {
    navigate("/");
  }

  return (
    <div className="tasks-wrapper">
      <Header />

      <div className="tasks-container">
        <div className="tasks-wrapper-left">Welcome {currentTherapist}</div>
        <div className="tasks-wrapper-right">
          <div className="tasks-list">
            <p>Select a task..</p>

            <button className="task-button" onClick={openPtientMain}>
              Patient Data
            </button>

            <form action="Admin/Cms">
              <button className="task-button" onClick={openCMS}>
                CMS
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Dashboard;
