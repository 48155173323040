import logo from "../assets/logo_cropped.webp";
function Header() {
  return (
    <header className="header-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" />
      </div>
      <div className="heading-container">
        <span id="title-heading">Senior Saathi</span>
        <span id="tagline">A guide to Healthy Ageing!</span>
      </div>
      <div className="languages-container">
        <span>Ln</span>
      </div>
    </header>
  );
}
export default Header;
